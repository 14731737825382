import { Route, Switch, Redirect, useLocation } from 'react-router';

import Login from 'pages/Login';
import Logout from 'pages/Logout';
import { InitiateAuth } from 'pages/InitiateAuth';

import AuthCallback from 'pages/AuthCallback';
import {
  Insights,
  Overview,
  CoSellDashboard,
  CoSellOpportunity,
  CoSellOpportunityForm,
  Disbursements,
  MeteringNewRecord,
  MeteringUpload,
  Metering,
  PrivateOffers,
  PrivateOffersNew,
  PrivateOffersExisting,
  ProspectScoring,
  Settings,
  Contract,
  IntegrationTools,
  IntegrationConfig,
  EventStream,
  Reporting,
  Listings,
  SignUp,
  Payments,
  PaymentsLegacyReports,
} from 'pages';
import CreatePassword from 'pages/Login/CreatePassword/CreatePassword';
import OhNo from 'pages/Error/Error';
import ActivityStream from 'pages/ActivityStream';
import { MobileRedirector } from 'components';

import { Root } from 'boot/components/Root';
import { PrivateRoute } from 'boot/components/PrivateRoute';
import RedirectWithAlert from 'components/RedirectWithAlert';
import { PermissionProtectedPage } from './PermissionProtectedPage';

import { WithCoSellPackages } from 'pages/CoSell/utilities/withCosellPackages';
import NotAvailableState, {
  NotAvailableReasonEnum,
} from 'components/NotAvailableState/NotAvailableState';
import { gql } from '@apollo/client';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import { useAuth } from 'vendor/auth0';
import { VendorEnvironmentEnum } from 'utils/constants';
import {
  AREA_PERMISSION_INTEGRATIONS,
  AREA_PERMISSION_METERING,
  AREA_PERMISSION_OFFERS,
  AREA_PERMISSION_PAYMENTS,
  AREA_PERMISSION_PROSPECT,
} from 'utils/rbacPermissions';
import { PaymentsFeatureFlags } from 'utils/features';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { FEATURE_CO_SELL_V3_UI_ENABLED } from 'pages/CoSell/utilities/constants';
import UnifiedOpportunityForm from 'packages/cosell/src/pages/UnifiedOpportunityForm';
export const CURRENT_USER_VENDOR_QUERY = gql`
  query currentUserVendor {
    currentUser {
      id
      vendor {
        id
        vendor_type
      }
    }
  }
`;

export function Routes() {
  const { isAuthenticated } = useAuth();
  const { data } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only', // reduce flicker - probably fine, this data does not change that often
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
  });
  const location = useLocation();
  const { flags: ampliFlags } = useAmpliFeatureFlags();
  const isCosellV3Enabled = ampliFlags[FEATURE_CO_SELL_V3_UI_ENABLED] === 'on';
  const isUnifiedCosellForm =
    isCosellV3Enabled &&
    location?.pathname?.includes('/co-sell/v3/opportunity/aws');
  const isPaymentsEnabled =
    ampliFlags[PaymentsFeatureFlags.UI_PAYMENTS] === 'on';
  const isSandbox =
    data?.currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX;

  return (
    <Switch>
      <Route exact path="/" render={() => <Root />} />
      <Route path="/login" render={() => <Login />} />
      <Route path="/logout" render={() => <Logout />} />
      <Route path="/auth/callback" component={AuthCallback} />
      <Route exact path="/auth/initiate" component={InitiateAuth} />
      <Route exact path="/auth/initiate/:vendorid" component={InitiateAuth} />
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/onboarding/signup" render={() => <Redirect to="/" />} />
      <RedirectWithAlert
        exact
        from="/onboarding" // this used to redirect to vendor config
        to="/settings/account/marketplace"
        message="Route to the street sharks"
      />
      <RedirectWithAlert
        // not exact
        from="/onboarding" // everything else can go to listings I guess
        to="/listings"
        message="Route to the street sharks"
      />
      <PrivateRoute
        path="/listings"
        render={() =>
          // individiual Listings routes permission protected in a different file
          isSandbox ? (
            <NotAvailableState
              reason={NotAvailableReasonEnum.SANDBOX}
              feature="Listings"
            />
          ) : (
            <Listings />
          )
        }
      />
      <PrivateRoute
        loadData
        path="/dashboard"
        render={() =>
          isSandbox ? (
            <NotAvailableState
              reason={NotAvailableReasonEnum.SANDBOX}
              feature="Dashboard"
            />
          ) : (
            <Overview />
          )
        }
      />
      <PrivateRoute
        loadData
        exact
        path="/insights"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Insights"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Insights"
            >
              <Insights />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute loadData exact path="/dashboard" component={Overview} />
      <PrivateRoute
        loadData
        exact
        path="/activity-stream"
        component={ActivityStream}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Payments"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Payments"
            >
              <Payments />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments/legacy"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Payments"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Payments"
            >
              <PaymentsLegacyReports />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments/legacy/:reportType/:provider/:group/:report"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Reporting"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Reporting"
            >
              <PaymentsLegacyReports />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments/legacy/:reportType/:provider/:group"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Reporting"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Reporting"
            >
              <PaymentsLegacyReports />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments/legacy/:reportType/:provider"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Reporting"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Reporting"
            >
              <PaymentsLegacyReports />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/payments/legacy/:reportType"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Payments"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Payments"
            >
              <PaymentsLegacyReports />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/co-sell"
        component={WithCoSellPackages(CoSellDashboard)}
      />
      {/* TODO: Remove /v3/ route when done testing & v3 is Live */}
      <PrivateRoute
        loadData
        exact
        path="/co-sell/v3/opportunity/:cloud"
        component={
          isUnifiedCosellForm
            ? UnifiedOpportunityForm
            : WithCoSellPackages(CoSellOpportunityForm)
        }
      />
      <PrivateRoute
        loadData
        exact
        path="/co-sell/opportunity/:cloud"
        component={WithCoSellPackages(CoSellOpportunityForm)}
      />
      <PrivateRoute
        loadData
        exact
        path="/co-sell/opportunity/:cloud/:opportunityId"
        component={WithCoSellPackages(CoSellOpportunity)}
      />
      <PrivateRoute
        loadData
        exact
        path="/metering/new"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Metering"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_METERING}
              pageFeatureName="Metering"
            >
              <MeteringNewRecord />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/metering/upload"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Metering"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_METERING}
              pageFeatureName="Metering"
            >
              <MeteringUpload />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/metering"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Metering"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_METERING}
              pageFeatureName="Metering"
            >
              <Metering />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/prospect"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Prospect"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PROSPECT}
              pageFeatureName="Prospect"
            >
              <ProspectScoring />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/private-offers"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Offers"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_OFFERS}
              pageFeatureName="Offers"
            >
              <PrivateOffers />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/private-offers/new"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Offers"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_OFFERS}
              pageFeatureName="Offers"
            >
              <PrivateOffersNew />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/private-offers/:poId"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Offers"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_OFFERS}
              pageFeatureName="Offers"
            >
              <PrivateOffersExisting />
            </PermissionProtectedPage>
          );
        }}
      />
      <Redirect
        exact
        from="/listings/:productid/orders/:customerid"
        to="/dashboard"
      />
      <Redirect
        exact
        from="/listings/:productid/customers/:customerid"
        to="/dashboard"
      />
      <Route
        exact
        path="/user/create-password/:sessionId"
        component={CreatePassword}
      />
      <PrivateRoute
        loadData
        exact
        path="/contracts/:contractid"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Insights"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Insights"
            >
              <Contract />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        path="/settings"
        render={() => (
          // individiual Settings routes permission protected in a different file
          <Settings />
        )}
      />
      <PrivateRoute
        loadData
        exact
        path="/events"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Insights"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Insights"
            >
              <EventStream />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/events/:eventid"
        render={() => {
          if (isSandbox)
            return (
              <NotAvailableState
                reason={NotAvailableReasonEnum.SANDBOX}
                feature="Insights"
              />
            );
          return (
            <PermissionProtectedPage
              permission={AREA_PERMISSION_PAYMENTS}
              pageFeatureName="Insights"
            >
              <EventStream />
            </PermissionProtectedPage>
          );
        }}
      />
      <PrivateRoute
        loadData
        exact
        path="/integrations"
        render={() => (
          <PermissionProtectedPage
            permission={AREA_PERMISSION_INTEGRATIONS}
            pageFeatureName="Integrations"
          >
            <IntegrationTools />
          </PermissionProtectedPage>
        )}
      />
      <Redirect exact path="/integrations/:app" to="/integrations" />
      <PrivateRoute
        loadData
        exact
        path="/integrations/:app/configuration"
        render={() => (
          <PermissionProtectedPage
            permission={AREA_PERMISSION_INTEGRATIONS}
            pageFeatureName="Integrations"
          >
            <IntegrationConfig />
          </PermissionProtectedPage>
        )}
      />
      {isPaymentsEnabled && (
        <>
          <Redirect from="/datafeeds" to="/payments" />
          <Redirect from="/disbursements" to="/payments" />
        </>
      )}
      {!isPaymentsEnabled && (
        <>
          <PrivateRoute
            loadData
            exact
            path="/disbursements"
            render={() => {
              if (isSandbox)
                return (
                  <NotAvailableState
                    reason={NotAvailableReasonEnum.SANDBOX}
                    feature="Disbursements"
                  />
                );
              return (
                <PermissionProtectedPage
                  permission={AREA_PERMISSION_PAYMENTS}
                  pageFeatureName="Disbursements"
                >
                  <Disbursements />
                </PermissionProtectedPage>
              );
            }}
          />
          <PrivateRoute
            loadData
            exact
            path="/datafeeds"
            render={() => {
              if (isSandbox)
                return (
                  <NotAvailableState
                    reason={NotAvailableReasonEnum.SANDBOX}
                    feature="Reporting"
                  />
                );
              return (
                <PermissionProtectedPage
                  permission={AREA_PERMISSION_PAYMENTS}
                  pageFeatureName="Reporting"
                >
                  <Reporting />
                </PermissionProtectedPage>
              );
            }}
          />
          <PrivateRoute
            loadData
            exact
            path="/datafeeds/:provider/:group/:report"
            render={() => {
              if (isSandbox)
                return (
                  <NotAvailableState
                    reason={NotAvailableReasonEnum.SANDBOX}
                    feature="Reporting"
                  />
                );
              return (
                <PermissionProtectedPage
                  permission={AREA_PERMISSION_PAYMENTS}
                  pageFeatureName="Reporting"
                >
                  <Reporting />
                </PermissionProtectedPage>
              );
            }}
          />
          <PrivateRoute
            loadData
            exact
            path="/datafeeds/:provider/:group"
            render={() => {
              if (isSandbox)
                return (
                  <NotAvailableState
                    reason={NotAvailableReasonEnum.SANDBOX}
                    feature="Reporting"
                  />
                );
              return (
                <PermissionProtectedPage
                  permission={AREA_PERMISSION_PAYMENTS}
                  pageFeatureName="Reporting"
                >
                  <Reporting />
                </PermissionProtectedPage>
              );
            }}
          />
          <PrivateRoute
            loadData
            exact
            path="/datafeeds/:provider"
            render={() => {
              if (isSandbox)
                return (
                  <NotAvailableState
                    reason={NotAvailableReasonEnum.SANDBOX}
                    feature="Reporting"
                  />
                );
              return (
                <PermissionProtectedPage
                  permission={AREA_PERMISSION_PAYMENTS}
                  pageFeatureName="Reporting"
                >
                  <Reporting />
                </PermissionProtectedPage>
              );
            }}
          />
        </>
      )}
      <Redirect exact from="/reporting-lite" to="/datafeeds" />
      <Redirect
        exact
        from="/reporting-lite/:provider/:group/:report"
        to="/datafeeds/:provider/:group/:report"
      />
      <Redirect
        exact
        from="/reporting-lite/:provider/:group"
        to="/datafeeds/:provider/:group"
      />
      <Redirect
        exact
        from="/reporting-lite/:provider"
        to="/datafeeds/:provider"
      />
      <Route exact path="/oh-no" component={OhNo} />
      <MobileRedirector
        exact
        from="/"
        desktopRoute="/dashboard"
        mobileRoute="/activity-stream"
      />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
}

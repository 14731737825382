import cosellClient from 'pages/CoSell/api/cosellClient';
import UnifiedOpportunityFormShell from '../UnifiedOpportunityFormShell';
import { AceOpportunityFormValues } from './AceOpportunityFormValues';
import { OpportunityResponse } from 'pages/CoSell/types/responses/OpportunityResponse';
import {
  CoSellCloudType,
  DisplayCloudType,
  OpportunityStatusEnum,
} from 'pages/CoSell/types/enums';
import { getInitialAceFormValues } from 'pages/CoSell/utilities/typeConverters/getInitialAceFormValues';
import { convertAceFormValuesToCreateRequest } from 'pages/CoSell/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { convertAceFormValuesToUpdateRequest } from 'pages/CoSell/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import { ampli } from 'utils/analytics/ampli';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import { useEffect, useMemo, useState } from 'react';
import { solutionsApiToFormFormatter } from 'pages/CoSell/utilities/formatSolutions';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';

type AceVendorSolution = {
  id: string;
  externalId: string;
  solutionName: string;
};

interface AceOpportunityFormProps {
  opportunity?: OpportunityResponse;
}
const AceOpportunityForm = ({ opportunity }: AceOpportunityFormProps) => {
  const [vendorSolutions, setVendorSolutions] = useState<AceVendorSolution[]>(
    [],
  );
  const { vendorId } = useOpportunity();

  useEffect(() => {
    const getVendorSolutions = async () => {
      let vendorSolutionsData;

      vendorSolutionsData =
        await cosellClient.getVendorSolutionsByVendorAndCloud(vendorId, 'ace');
      setVendorSolutions(vendorSolutionsData);
    };

    getVendorSolutions();
  }, [vendorId]);

  const solutionList = useMemo(() => {
    return solutionsApiToFormFormatter(vendorSolutions);
  }, [vendorSolutions]);

  const handleCreateOrUpdateOpportunity = async (
    values: AceOpportunityFormValues,
  ) => {
    const isExistingOpportunity = !!opportunity?.id;
    const updateRequestBody = convertAceFormValuesToUpdateRequest(
      values,
      vendorId,
    );
    const requestBody = convertAceFormValuesToCreateRequest(values, vendorId);
    return isExistingOpportunity
      ? await cosellClient.updateOpportunity(opportunity.id, updateRequestBody)
      : await cosellClient.createOpportunity(requestBody);
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: AceOpportunityFormValues,
  ) => {
    try {
      // Set Status to Submitting to Trigger Submission to Cloud
      values.status = OpportunityStatusEnum.SUBMITTING;
      const submittedOpportunity = handleCreateOrUpdateOpportunity(values);
      ampli.outboundSubmitted({ cloud: 'aws' });
      return submittedOpportunity;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: AceOpportunityFormValues,
  ) => {
    // Set Status to Draft or Internal review to Trigger Draft Creation
    values.status =
      values.status && values.status === OpportunityStatusEnum.INTERNAL_REVIEW
        ? OpportunityStatusEnum.INTERNAL_REVIEW
        : OpportunityStatusEnum.DRAFT;
    const saveDraftOpportunity = handleCreateOrUpdateOpportunity(values);
    return saveDraftOpportunity;
  };

  const handleDeleteAceDraftOpportunity = async () => {
    await cosellClient.deleteDraftOpportunity(
      vendorId,
      CoSellCloudType.ACE,
      opportunity.id,
    );
  };

  return (
    <UnifiedOpportunityFormShell
      opportunity={opportunity}
      onSubmit={handleSubmitAceOpportunityToCloud}
      onSaveAsDraft={handleSaveAceOpportunityAsDraft}
      onDeleteDraft={handleDeleteAceDraftOpportunity}
      validationSchema={aceOpportunityFormValidationSchema}
      initialValues={getInitialAceFormValues(opportunity)}
      provider={DisplayCloudType.AWS}
      isAceV14={true}
    >
      <AceOpportunityFormFields
        opportunity={opportunity}
        solutionList={solutionList}
      />
    </UnifiedOpportunityFormShell>
  );
};

export default AceOpportunityForm;

import { getInvoiceDateTimeDisplayFromDate } from 'pages/PrivateOffers/utils/formatFormData';
import React, { useEffect, useRef, useState } from 'react';
import { floatToDinero } from 'utils/money';
import { Box, Grid, Typography } from 'vendor/material';
import useStyles from './PaymentSchedulePreview.styles';
import { PaymentScheduleFormData } from './PaymentScheduler';
import {
  PaymentScheduleRow,
  calculateNumberOfPayments,
  getPaymentFrequencyTextForSubtitle,
} from './utils';

interface PaymentSchedulePreviewProps {
  schedule: PaymentScheduleRow[];
  paymentScheduleData: PaymentScheduleFormData;
}

const PaymentSchedulePreview: React.FC<PaymentSchedulePreviewProps> = ({
  schedule,
  paymentScheduleData,
}) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const rowsContainerRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    const el = rowsContainerRef?.current;

    if (!el) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (el.scrollHeight > el.clientHeight) {
        setIsScrolledToBottom(false);
      }
    });

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleScroll = (e) => {
    const isScrolledToBottom =
      e.target.scrollHeight - Math.floor(e.target.scrollTop) ===
      e.target.clientHeight;
    if (isScrolledToBottom) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  const numberOfPayments = calculateNumberOfPayments(
    paymentScheduleData.durationInMonths,
    paymentScheduleData.paymentFrequency,
  );

  const paymentFrequencyText = getPaymentFrequencyTextForSubtitle(
    paymentScheduleData.paymentFrequency,
  );

  const title = `Payment schedule preview with ${numberOfPayments} ${paymentFrequencyText} payments`;

  return (
    <Box>
      <Typography component="p" className={classes.subtitle}>
        {title}
      </Typography>
      <Grid container className={classes.nameContainer}>
        <Grid item xs={3}>
          <Typography component="h5" className={classes.name}>
            Number
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography component="h5" className={classes.name}>
            Invoice date
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="h5" align="right" className={classes.name}>
            Payment amount
          </Typography>
        </Grid>
      </Grid>
      <Box
        className={`${classes.rowsContainer} ${
          isScrolledToBottom ? '' : classes.scrollShadow
        }`}
        onScroll={handleScroll}
        // Known limitation of Box MUI component not typed for `ref`
        // even though it works fine: https://github.com/mui/material-ui/issues/17010
        // @ts-ignore
        ref={rowsContainerRef}
      >
        {schedule.map((item, index) => (
          <Grid
            container
            key={`${item.invoiceAmount}${item.invoiceDate}`}
            className={classes.rows}
          >
            <Grid item xs={3}>
              <Typography component="p" className={classes.value}>
                {index + 1}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="p" className={classes.value}>
                {getInvoiceDateTimeDisplayFromDate(item.invoiceDate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" align="right" className={classes.value}>
                {floatToDinero(item.invoiceAmount).toFormat('$0,0.00')}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.totalPaymentsContainer}
      >
        <Typography
          component="span"
          className={`${classes.value} ${classes.totalPayments}`}
        >
          Total payments
        </Typography>
        <Typography component="span" className={classes.value}>
          {floatToDinero(paymentScheduleData.totalContractValue).toFormat(
            '$0,0.00',
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentSchedulePreview;

export const convertBooleanToYesNo = (
  value: boolean | null,
  defaultValue: string = '',
) => {
  switch (value) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return defaultValue;
  }
};

import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  StandardTextFieldProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from 'vendor/material';
import { InformationOutline } from 'mdi-material-ui';
import { Button, TextField, Spinner } from '@tackle-io/platform-ui';
import { useFormikContext } from 'formik';
import { Label } from 'pages/CoSell/components/Label/Label';
import useStyles from './AiTextFormField.styles';
import { vendorStore } from 'stores/Vendor';
import { gql } from '@apollo/client';
import { useCosellAiBusinessProblemDescriptionLazyQuery } from 'generated/graphql';
import { useIntercom } from 'react-use-intercom';
import InsightsIcon from 'pages/CoSell/components/InsightsIcon/InsightsIcon';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

interface AiTextFormFieldProps extends StandardTextFieldProps {
  cloud?: string;
  dependsOnFields?: string[];
  label: string;
  maxLength?: number;
  minLength?: number;
  multiline?: boolean;
  placeholder?: string;
  rows?: number;
  required?: boolean;
  tooltipText?: string;
  pendoId?: string;
}

export const AI_GENERATE_COSELL_DESCRIPTION = gql`
  query cosellAiBusinessProblemDescription(
    $vendorId: String!
    $buyerDomain: String!
    $targetCloud: String!
  ) {
    coSellAiBusinessProblemDescription(
      vendor_id: $vendorId
      buyer_domain: $buyerDomain
      target_cloud: $targetCloud
    ) {
      result
    }
  }
`;

export const AiTextFormField: React.FC<AiTextFormFieldProps> = ({
  name,
  cloud = 'aws',
  dependsOnFields = undefined,
  label,
  maxLength,
  minLength,
  multiline = false,
  rows = 5,
  required = false,
  tooltipText,
  placeholder,
  pendoId,
  ...rest
}) => {
  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext<UnifiedOpportunityFormValues>();
  const classes = useStyles();
  const [syntheticLoading, setSyntheticLoading] = useState<boolean>(false);
  const [genAiPressed, setGenAiPressed] = useState<boolean>(false);
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(930));
  const vendorId = vendorStore.vendor?.id;
  const updateField = handleChange(name);
  const { trackEvent } = useIntercom();

  const [getDescription, { data, error: gqlError, loading }] =
    useCosellAiBusinessProblemDescriptionLazyQuery({
      onCompleted(data) {
        const newData =
          values[name].length > 0
            ? `${values[name]}\n\n ${data.coSellAiBusinessProblemDescription.result}`
            : data.coSellAiBusinessProblemDescription.result;
        updateField(newData);
        setSyntheticLoading(false);
        trackEvent('cosell-generate-ai-description-success', {
          vendorId,
        });
      },
      onError(error) {
        setSyntheticLoading(false);
      },
      notifyOnNetworkStatusChange: true,
    });

  const combinedLoading = useMemo(() => {
    return syntheticLoading || loading;
  }, [loading, syntheticLoading]);

  const generateDisabled: boolean = useMemo(() => {
    if (!dependsOnFields) return false;
    if (combinedLoading) return true;
    // button is disabled if any required field is missing OR has errors.
    return dependsOnFields.some(
      (field) => !values[field] || !!errors[field] === true,
    );
  }, [dependsOnFields, errors, combinedLoading, values]);

  const fieldErrors = useMemo(() => {
    if (touched[name] && errors[name]) {
      return errors[name];
    }
    if (!!gqlError?.message) {
      return 'Something went wrong, please try generating again.';
    }
    return false;
  }, [errors, gqlError?.message, name, touched]);

  const textFieldLabel = useMemo(() => {
    return tooltipText ? (
      <>
        <Box className={classes.formLabel}>
          <Label label={label} required={required} />
          <Tooltip
            id={pendoId}
            className={classes.tooltip}
            title={tooltipText}
            placement="top-start"
          >
            <InformationOutline fontSize="small" />
          </Tooltip>
        </Box>
      </>
    ) : (
      <Label label={label} required={required} />
    );
  }, [
    classes.formLabel,
    classes.tooltip,
    label,
    pendoId,
    required,
    tooltipText,
  ]);

  const LoadingTextField = useCallback(() => {
    return (
      <TextField
        id={name}
        name={name}
        label={textFieldLabel}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        onChange={updateField}
        onBlur={handleBlur}
        value={values[name]}
        error={fieldErrors}
        {...(genAiPressed
          ? {
              helperText:
                "Tackle AI's description is intended to be a starting point. Please review for accuracy before sharing with the cloud",
            }
          : {})}
        {...rest}
      />
    );
  }, [
    fieldErrors,
    genAiPressed,
    handleBlur,
    maxLength,
    minLength,
    multiline,
    name,
    placeholder,
    rest,
    rows,
    textFieldLabel,
    updateField,
    values,
  ]);

  const handleGenerateRequest = () => {
    setGenAiPressed(true);
    getDescription({
      variables: {
        vendorId: vendorId,
        // this variable should be more dynamic at _some point_ but for now
        // this is the only field we need/can use for this.
        buyerDomain: values['customerWebsite'],
        targetCloud: cloud,
      },
    });
    if (!!data?.coSellAiBusinessProblemDescription?.result) {
      setSyntheticLoading(true);
      const newData =
        values[name].length > 0
          ? `${values[name]}\n\n ${data.coSellAiBusinessProblemDescription.result}`
          : data.coSellAiBusinessProblemDescription.result;

      setTimeout(() => {
        // pretend to send another network request
        updateField(newData);
        setSyntheticLoading(false);
      }, 500);
    }
  };

  return (
    <div className={classes.cosellFormAiTextField}>
      <div className={classes.cosellFormTopSection}>
        <InsightsIcon />
        <Typography
          data-id={`${name}-tackle-ai-help`}
          id={`${name}-tackle-ai-help`}
          className={classes.tackleAiLogoText}
        >
          Tackle AI
        </Typography>
      </div>
      {combinedLoading ? (
        <div>
          <LoadingTextField />
        </div>
      ) : (
        <TextField
          id={name}
          name={name}
          label={textFieldLabel}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          onChange={updateField}
          onBlur={handleBlur}
          value={values[name]}
          error={fieldErrors}
          {...(genAiPressed
            ? {
                helperText:
                  "Tackle AI's description is intended to be a starting point. Please review for accuracy before sharing with the cloud",
              }
            : {})}
          {...rest}
        />
      )}

      <div
        className={
          screenMobile
            ? classes.bottomGenerateRowSmall
            : classes.bottomGenerateRow
        }
      >
        <Button
          id={`${name}-ai-generate-button`}
          data-id={`${name}-ai-generate-button`}
          className={classes.generateWithAiButton}
          onClick={handleGenerateRequest}
          disabled={generateDisabled || rest.disabled}
          variant="outlined"
          appearance="primary"
        >
          Generate with Tackle AI
        </Button>
        <span className={classes.loadingSpinner}>
          {combinedLoading && <Spinner type="ellipsis" />}
        </span>
      </div>
    </div>
  );
};

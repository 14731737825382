import { SelectFormFieldOption } from '../../types';

export const GCP_PRODUCT_FAMILY = 'Google Cloud Platform';

export const ProductFamilyList: SelectFormFieldOption[] = [
  {
    title: GCP_PRODUCT_FAMILY,
    value: GCP_PRODUCT_FAMILY,
  },
];

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull downstream-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 54
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/tackle/Tackle%20Platform/implementation/downstream-app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'staging' | 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  staging: '719883c30381cf2350ff0c98121c5188',
  production: '318e3ee8e9c50dacdcf9508604b6ac7b',
  development: '2f047d213d23f9b18882f60a6fe7cfda'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '54',
    branch: 'main',
    source: 'downstream-app',
    versionId: 'b61d8733-c05e-4ddb-8150-c4bfdac6aaed'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * A list of vendors IDs the user has access to
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  allowed_vendorids?: string[];
  /**
   * The user's primary role at their company answer from the Onboarding Profiling survey
   */
  company_primary_role?: string;
  email?: string;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_li_fat_id?: string;
  initial_msclkid?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  initial_rtd_cid?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  /**
   * First seen value for `utm_campaign` (see [utm_campaign](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_campaign?tab=DETAILS) for full description)
   */
  initial_utm_campaign?: string;
  /**
   * First seen value for `utm_content` (see [utm_content](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_content?tab=DETAILS) for full description)
   */
  initial_utm_content?: string;
  /**
   * First seen value for `utm_id` (see [utm_id](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_id?tab=DETAILS) for full description)
   */
  initial_utm_id?: string;
  /**
   * First seen value for `utm_medium` (see [utm_medium](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_medium?tab=DETAILS) for full description)
   */
  initial_utm_medium?: string;
  /**
   * First seen value for `utm_source` (see [utm_source](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_source?tab=DETAILS) for full description)
   */
  initial_utm_source?: string;
  /**
   * First seen value for `utm_term` property (see [utm_term](https://app.amplitude.com/data/tackle/Tackle%20Platform/properties/utm-descriptions/latest/user/utm_term?tab=DETAILS) for full description)
   */
  initial_utm_term?: string;
  initial_wbraid?: string;
  /**
   * interested_cloud_marketplaces answer from Onboarding Profiling survey
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  interested_cloud_marketplaces?: string[];
  name?: string;
  referrer?: string;
  referring_domain?: string;
  role?: string;
  /**
   * Contains the user's Profiling Survey response for User CRM goals.
   *
   *  "Do you currently or plan to extend cloud operations into Salesforce?"
   */
  user_crm_goals?: string;
  /**
   * user_goals answer result from Onboarding Profiling survey
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  user_goals?: string[];
  /**
   * Used to identify users associated with server-side events that do not have a User ID available, so we can easily exclude them from analysis when required.
   *
   * Values:
   *
   * * machine_user
   *
   * * (none)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | machine_user |
   */
  user_type?: "machine_user";
  /**
   * Used to identify which campaign the promotion is associated with. Example values: custom-branding, free-trial, etc
   */
  utm_campaign?: string;
  /**
   * Used to identify the exact element on your ad or promotion that was clicked. This is often used for optimization purposes. Example values: video_ad, text_ad, dashboard_banner , green_banner
   */
  utm_content?: string;
  /**
   * Used to identify the ID of the specific product promotion or strategic campaign.
   */
  utm_id?: string;
  /**
   * Used to show which marketing channels are bringing the visitor to your site. Example values: website, cpc, organic_social
   */
  utm_medium?: string;
  /**
   * Used to show which site the visitors are coming from. Example values: facebook, twitter, blog, newsletter, etc.
   */
  utm_source?: string;
  /**
   * Used to manually identify paid keywords you’re targeting with your campaign. Example values: cloud_gtm, tackle
   */
  utm_term?: string;
  vendor_id?: string;
}

export interface AmplitudeFormSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Form Destination"?: string;
}

export interface AmplitudePageViewedProperties {
  "[Amplitude] Page Domain"?: any;
  "[Amplitude] Page Location"?: any;
  "[Amplitude] Page Path"?: any;
  "[Amplitude] Page Title"?: any;
  "[Amplitude] Page URL"?: any;
  referrer?: any;
  referring_domain?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source?: string;
}

export interface AccountActionMenuItemClickedProperties {
  /**
   * Describes what action was taken in the prospect account row. Usually an offer being created.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | create_direct_offer, create_partner_offer |
   */
  action_type: "create_direct_offer" | "create_partner_offer";
}

export interface AccountActionMenuOpenedProperties {
  account_domain: string;
}

export interface AccountDetailsProperties {
  account_domain: string;
}

export interface AccountDetailsInteractionProperties {
  /**
   * Describes what action was taken in the prospect account details side panel. Usually a link or button being clicked.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | offers_clicked, mp_model_tab_clicked |
   */
  action_type: "offers_clicked" | "mp_model_tab_clicked";
}

export interface AccountScoresRequestedProperties {
  /**
   * Indicates where in downstream this request for scores was created from. Refers to either the main system dashboard, or the prospect page. Zero states or with data are seperated.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | dashboard_zero_state, prospect_page_zero_state, prospect_page_filled, dashboard_filled, salesforce |
   */
  request_location:
    | "dashboard_zero_state"
    | "prospect_page_zero_state"
    | "prospect_page_filled"
    | "dashboard_filled"
    | "salesforce";
  /**
   * Bulk represents csv/xls file upload, single is a per-domain request
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single, bulk |
   */
  scoring_type: "single" | "bulk";
}

export interface BrandDraftUpdatedProperties {
  /**
   * The `brand_id` is the `event_brands` uuid in the Postgres Database.
   */
  brand_id: string;
}

export interface BrandPublishedProperties {
  /**
   * The `brand_id` is the `event_brands` uuid in the Postgres Database.
   */
  brand_id: string;
}

export interface ButtonClickedProperties {
  /**
   * Location in the app where the button exists (e.g. dashboard, offers, settings)
   */
  button_location: string;
  button_name: string;
  /**
   * Product domain to which the button pertains
   */
  button_product_area: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  button_type?: string;
}

export interface CoSellConnectedProperties {
  cloud: string;
}

export interface CoSellOpportunitiesBulkLinkedProperties {
  /**
   * Location in the app where the button exists (e.g. dashboard, offers, settings)
   */
  button_location: string;
  button_name: string;
  /**
   * Product domain to which the button pertains
   */
  button_product_area: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  button_type?: string;
  /**
   * This is an event property to track the cloud value for the listing created.
   */
  cloud: string;
}

export interface CustomRoleAssignedProperties {
  custom_role_id: string;
  custom_role_name: string;
  organization_id: string;
}

export interface CustomRoleCopiedProperties {
  custom_role_id?: string;
  custom_role_name?: string;
  organization_id: string;
  origin_custom_role_id: string;
  origin_custom_role_name: string;
}

export interface CustomRoleCreatedProperties {
  custom_role_id: string;
  custom_role_name: string;
  organization_id: string;
}

export interface CustomRoleDeletedProperties {
  custom_role_id: string;
  custom_role_name: string;
  organization_id: string;
}

export interface CustomRoleEditedProperties {
  custom_role_id: string;
  custom_role_name: string;
  organization_id: string;
}

export interface CustomTextEditedProperties {
  brand_id?: string;
}

export interface DataFilterAppliedProperties {
  /**
   * The category of filter to be applied to data
   */
  filter_type: string;
  /**
   * The values selected as the filter on the data
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_values: string[];
  /**
   * Source Downstream page
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source: string;
}

export interface ExportScoredAccountsProperties {
  /**
   * Which filters are being used to export scores in Prospect.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  export_filters: (
    | "score"
    | "marketplace"
    | "file"
    | "industry"
    | "region"
    | "revenue"
    | "new_highs"
    | "unique_highs"
    | "search_field"
  )[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | bulk, selected |
   */
  export_type: "bulk" | "selected";
}

export interface ImportButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_aws_attempted_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_aws_imported_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_azure_attempted_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_azure_imported_count: number;
}

export interface InboundReceivedProperties {
  cloud: string;
}

export interface IntegrationSavedProperties {
  /**
   * The type of integration
   */
  type: string;
}

export interface IntegrationTestedProperties {
  type: string;
}

export interface IntegrationToggledProperties {
  /**
   * enabled or disabled
   */
  toggle: string;
  type: string;
}

export interface ListingPreviewViewedProperties {
  /**
   * This is an event property to track the cloud value for the listing created.
   */
  cloud: string;
  /**
   * This is an event property to track the generated product ID internal value for the listing created.
   */
  productid_internal: string;
}

export interface ListingRegistrationPreviewViewedProperties {
  /**
   * This is an event property to track the cloud value for the listing created.
   */
  cloud: string;
  /**
   * This is an event property to track the generated product ID internal value for the listing created.
   */
  productid_internal: string;
}

export interface OfferExtendedProperties {
  /**
   * This is an event property to track the cloud value for the listing created.
   */
  cloud: string;
  offer_create_type?: string;
  /**
   * Represents the tackle private offer id.
   */
  offer_id: string;
  offer_type: string;
}

export interface OfferPageRelatedItemClickedProperties {
  /**
   * Represents which "related item" was clicked in the private offers review window. This typically represents a link to a different area of the tackle web app.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | prospect_score |
   */
  related_item_clicked: "prospect_score";
}

export interface OffersClickedProperties {
  /**
   * Which marketplace was the offer from
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | aws, gcp, azure |
   */
  marketplace: "aws" | "gcp" | "azure";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | account_details, column |
   */
  offer_link_location: "account_details" | "column";
}

export interface OffersPageViewedProperties {
  /**
   * For replacement offers, this is the unique identifier of the source offer in the cloud marketplace, e.g. offer-6dh4rutgy787.
   */
  abo_linked_offer_ref?: string;
  offer_type?: string;
  offers_page_name?: string;
  /**
   * For replacement offers, this is the source offer's po_id, the unique identifier for a Tackle offer.
   */
  source_offer_po_id?: string;
}

export interface OutboundSubmittedProperties {
  cloud: string;
}

export interface ProspectPageFiltersAppliedProperties {
  /**
   * Determines if a file filter is present. Single select values are possible here, so is a yes or no.
   */
  file_filter: boolean;
  /**
   * Determines if the has_offers filter is present. Boolean value only.
   */
  has_offers_filter: boolean;
  /**
   * Determines if an industry filter is present, and if it has one or many values.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_select, multi_select, empty |
   */
  industries_filter: "single_select" | "multi_select" | "empty";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_select, multi_select, empty |
   */
  marketplace_filter: "single_select" | "multi_select" | "empty";
  /**
   * Determines if a region filter is present, and if it has one or many values.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_select, multi_select, empty |
   */
  region_filter: "single_select" | "multi_select" | "empty";
  /**
   * Determines if a revenue band filter is active, and if it has one or many values set
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_select, multi_select, empty |
   */
  revenue_band_filter: "single_select" | "multi_select" | "empty";
  /**
   * Determines if a scores filter has been applied, and if it has one or many values.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_select, multi_select, empty |
   */
  scores_filter: "single_select" | "multi_select" | "empty";
}

export interface RefineAccountListProperties {
  /**
   * which filter is being used to refine scores in prospect
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | score, file, marketplace, industry, region, revenue, new_highs, unique_highs, search |
   */
  "filter_ name":
    | "score"
    | "file"
    | "marketplace"
    | "industry"
    | "region"
    | "revenue"
    | "new_highs"
    | "unique_highs"
    | "search";
  /**
   * Determines if we have more than one option selected in a filter drop-down menu
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | single_value, multiple_values |
   */
  filter_selection: "single_value" | "multiple_values";
  /**
   * what page are we on? Used to determine if a user has actually gone through more than one page of results.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pagination: number;
  /**
   * is the search filter active?
   */
  search?: string;
}

export interface ReportDownloadedProperties {
  /**
   * The secondary name of the selected report
   */
  child_report_name?: string;
  /**
   * The selected cloud
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | aws, azure, gcp |
   */
  cloud: "aws" | "azure" | "gcp";
  /**
   * The primary name of the selected report
   */
  parent_report_name: string;
  /**
   * The source page on Downstream
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source: string;
}

export interface ResourceClickedProperties {
  resource_external_url: string;
  resource_location: string;
  resource_name: string;
  resource_product_area: string;
}

export interface SsoConfigSavedProperties {
  /**
   * Provider
   */
  idp?: string;
}

export interface TestEmailSentProperties {
  brand_id?: string;
  /**
   * This is an event property to track the cloud value for the listing created.
   */
  cloud: string;
  event_type: string;
}

export interface UserDeletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_id: string;
  user_role: string;
}

export interface UserInvitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_id: string;
  user_role: string;
  vendor_id?: string;
}

export interface UserModifiedProperties {
  previous_user_role: string;
  /**
   * The identifier of the user that was modified
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_id: string;
  user_role: string;
}

export interface UserSignedUpProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_id: string;
  /**
   * The vendor ID to attach to the events. Use only when vendor ID is not captured as a groups trait. Refer to enablement documentation for more details https://tackle.atlassian.net/wiki/spaces/GROWTH/pages/1020887618/Usage+Tracking+-+Getting+Started+Best+Practices#Providing-the-groups-trait-with-vendor_id-using-an-Amplitude-plugin
   */
  vendor_id: string;
}

export interface VendorAccountSwitchedProperties {
  /**
   * The new vendor ID after the switch
   */
  new_vendor_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source?: string;
}

export interface VendorEnvironmentSwitchedProperties {
  /**
   * The new vendor ID after the switch
   */
  new_vendor_id: string;
  /**
   * The new vendor_type after the switch (prod, sandbox, demo, etc.)
   */
  new_vendor_type: string;
}

export interface VideoPlayedProperties {
  /**
   * Identifier of the video from the hosting platform
   */
  video_id: string;
  /**
   * Location in the product that the video was played from
   */
  video_location: string;
  video_name: string;
  /**
   * Video hosting platform (vimeo, youtube, etc.)
   */
  video_platform: string;
  /**
   * Time in seconds at which the user stopped watching the video
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  video_play_duration: number;
  /**
   * Product domain to which the video content pertains
   */
  video_product_area: string;
  /**
   * Time in seconds of the total duration of the video
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  video_total_duration: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeFormSubmitted implements BaseEvent {
  event_type = '[Amplitude] Form Submitted';

  constructor(
    public event_properties?: AmplitudeFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountActionMenuItemClicked implements BaseEvent {
  event_type = 'Account Action Menu Item Clicked';

  constructor(
    public event_properties: AccountActionMenuItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountActionMenuOpened implements BaseEvent {
  event_type = 'Account Action Menu Opened';

  constructor(
    public event_properties: AccountActionMenuOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountDetails implements BaseEvent {
  event_type = 'Account Details';

  constructor(
    public event_properties: AccountDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountDetailsInteraction implements BaseEvent {
  event_type = 'Account Details Interaction';

  constructor(
    public event_properties: AccountDetailsInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountScoresRequested implements BaseEvent {
  event_type = 'Account Scores Requested';

  constructor(
    public event_properties: AccountScoresRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandDraftCreated implements BaseEvent {
  event_type = 'Brand Draft Created';
}

export class BrandDraftUpdated implements BaseEvent {
  event_type = 'Brand Draft Updated';

  constructor(
    public event_properties: BrandDraftUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandLogoUploaded implements BaseEvent {
  event_type = 'Brand Logo Uploaded';
}

export class BrandPublished implements BaseEvent {
  event_type = 'Brand Published';

  constructor(
    public event_properties: BrandPublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ButtonClicked implements BaseEvent {
  event_type = 'Button Clicked';

  constructor(
    public event_properties: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelImportButtonClicked implements BaseEvent {
  event_type = 'Cancel Import Button Clicked';
}

export class CoSellConnected implements BaseEvent {
  event_type = 'Co-sell Connected';

  constructor(
    public event_properties: CoSellConnectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CoSellOpportunitiesBulkLinked implements BaseEvent {
  event_type = 'Co-sell Opportunities Bulk Linked';

  constructor(
    public event_properties: CoSellOpportunitiesBulkLinkedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomRoleAssigned implements BaseEvent {
  event_type = 'Custom Role Assigned';

  constructor(
    public event_properties: CustomRoleAssignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomRoleCopied implements BaseEvent {
  event_type = 'Custom Role Copied';

  constructor(
    public event_properties: CustomRoleCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomRoleCreated implements BaseEvent {
  event_type = 'Custom Role Created';

  constructor(
    public event_properties: CustomRoleCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomRoleDeleted implements BaseEvent {
  event_type = 'Custom Role Deleted';

  constructor(
    public event_properties: CustomRoleDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomRoleEdited implements BaseEvent {
  event_type = 'Custom Role Edited';

  constructor(
    public event_properties: CustomRoleEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomTextEdited implements BaseEvent {
  event_type = 'Custom Text Edited';

  constructor(
    public event_properties?: CustomTextEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataFilterApplied implements BaseEvent {
  event_type = 'Data Filter Applied';

  constructor(
    public event_properties: DataFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportScoredAccounts implements BaseEvent {
  event_type = 'Export Scored Accounts';

  constructor(
    public event_properties: ExportScoredAccountsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportButtonClicked implements BaseEvent {
  event_type = 'Import Button Clicked';

  constructor(
    public event_properties: ImportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportFromMarketplaceButtonClicked implements BaseEvent {
  event_type = 'Import From Marketplace Button Clicked';
}

export class InboundReceived implements BaseEvent {
  event_type = 'Inbound Received';

  constructor(
    public event_properties: InboundReceivedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSaved implements BaseEvent {
  event_type = 'Integration Saved';

  constructor(
    public event_properties: IntegrationSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationTested implements BaseEvent {
  event_type = 'Integration Tested';

  constructor(
    public event_properties: IntegrationTestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationToggled implements BaseEvent {
  event_type = 'Integration Toggled';

  constructor(
    public event_properties: IntegrationToggledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingPreviewViewed implements BaseEvent {
  event_type = 'Listing Preview Viewed';

  constructor(
    public event_properties: ListingPreviewViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingRegistrationPreviewViewed implements BaseEvent {
  event_type = 'Listing Registration Preview Viewed';

  constructor(
    public event_properties: ListingRegistrationPreviewViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketplaceInsightsCardClosed implements BaseEvent {
  event_type = 'Marketplace Insights Card Closed';
}

export class MarketplaceInsightsListingRequirementsClicked implements BaseEvent {
  event_type = 'Marketplace Insights Listing Requirements Clicked';
}

export class MarketplaceInsightsViewAccountsClicked implements BaseEvent {
  event_type = 'Marketplace Insights View Accounts Clicked';
}

export class OfferExtended implements BaseEvent {
  event_type = 'Offer Extended';

  constructor(
    public event_properties: OfferExtendedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OfferPageRelatedItemClicked implements BaseEvent {
  event_type = 'Offer Page Related Item Clicked';

  constructor(
    public event_properties: OfferPageRelatedItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OffersClicked implements BaseEvent {
  event_type = 'Offers Clicked';

  constructor(
    public event_properties: OffersClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OffersPageViewed implements BaseEvent {
  event_type = 'Offers Page Viewed';

  constructor(
    public event_properties?: OffersPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OutboundSubmitted implements BaseEvent {
  event_type = 'Outbound Submitted';

  constructor(
    public event_properties: OutboundSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProspectPageFiltersApplied implements BaseEvent {
  event_type = 'Prospect Page Filters Applied';

  constructor(
    public event_properties: ProspectPageFiltersAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RefineAccountList implements BaseEvent {
  event_type = 'Refine Account List';

  constructor(
    public event_properties: RefineAccountListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReportDownloaded implements BaseEvent {
  event_type = 'Report Downloaded';

  constructor(
    public event_properties: ReportDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResourceClicked implements BaseEvent {
  event_type = 'Resource Clicked';

  constructor(
    public event_properties: ResourceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RolesAndPermissionsTabVisited implements BaseEvent {
  event_type = 'Roles and Permissions Tab Visited';
}

export class SessionEnd implements BaseEvent {
  event_type = 'session_end';
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';
}

export class SignUpStepCompletedCompanyInformation implements BaseEvent {
  event_type = 'Sign Up Step Completed - Company Information';
}

export class SignUpStepCompletedPassword implements BaseEvent {
  event_type = 'Sign Up Step Completed - Password';
}

export class SignUpStepCompletedPersonalInformation implements BaseEvent {
  event_type = 'Sign Up Step Completed - Personal Information';
}

export class SsoConfigSaved implements BaseEvent {
  event_type = 'SSO Config Saved';

  constructor(
    public event_properties?: SsoConfigSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TestEmailSent implements BaseEvent {
  event_type = 'Test Email Sent';

  constructor(
    public event_properties: TestEmailSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserDeleted implements BaseEvent {
  event_type = 'User Deleted';

  constructor(
    public event_properties: UserDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserInvited implements BaseEvent {
  event_type = 'User Invited';

  constructor(
    public event_properties: UserInvitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserModified implements BaseEvent {
  event_type = 'User Modified';

  constructor(
    public event_properties: UserModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserSignedUp implements BaseEvent {
  event_type = 'User Signed Up';

  constructor(
    public event_properties: UserSignedUpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VendorAccountSwitched implements BaseEvent {
  event_type = 'Vendor Account Switched';

  constructor(
    public event_properties: VendorAccountSwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VendorEnvironmentSwitched implements BaseEvent {
  event_type = 'Vendor Environment Switched';

  constructor(
    public event_properties: VendorEnvironmentSwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VideoPlayed implements BaseEvent {
  event_type = 'Video Played';

  constructor(
    public event_properties: VideoPlayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/%5BAmplitude%5D%20Form%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Form Destination)
   * @param options Amplitude event options.
   */
  amplitudeFormSubmitted(
    properties?: AmplitudeFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeFormSubmitted(properties), options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Owner: Dylan
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * Account Action Menu Item Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Account%20Action%20Menu%20Item%20Clicked)
   *
   * Actions clicked from the “...” more actions menu option on each account row in Prospect.
   *
   * @param properties The event's properties (e.g. action_type)
   * @param options Amplitude event options.
   */
  accountActionMenuItemClicked(
    properties: AccountActionMenuItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountActionMenuItemClicked(properties), options);
  }

  /**
   * Account Action Menu Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Account%20Action%20Menu%20Opened)
   *
   * User clicked the “`...”` to open the menu on a Prospect account row.
   *
   * @param properties The event's properties (e.g. account_domain)
   * @param options Amplitude event options.
   */
  accountActionMenuOpened(
    properties: AccountActionMenuOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountActionMenuOpened(properties), options);
  }

  /**
   * Account Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Account%20Details)
   *
   * This event tracks how users interact with an individual account’s details in Tackle Prospect.
   *
   * @param properties The event's properties (e.g. account_domain)
   * @param options Amplitude event options.
   */
  accountDetails(
    properties: AccountDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountDetails(properties), options);
  }

  /**
   * Account Details Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Account%20Details%20Interaction)
   *
   * Actions that users take from the account details side panel in Prospect.
   *
   * @param properties The event's properties (e.g. action_type)
   * @param options Amplitude event options.
   */
  accountDetailsInteraction(
    properties: AccountDetailsInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountDetailsInteraction(properties), options);
  }

  /**
   * Account Scores Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Account%20Scores%20Requested)
   *
   * This event fires when a user has actually requested scores, either by attempting to upload a file, or by providing a domain to single-domain scoring. This is done prior to the network request so we know this took place even if the request fails.
   *
   * Owner: Andy Hahn
   *
   * @param properties The event's properties (e.g. request_location)
   * @param options Amplitude event options.
   */
  accountScoresRequested(
    properties: AccountScoresRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountScoresRequested(properties), options);
  }

  /**
   * Brand Draft Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Brand%20Draft%20Created)
   *
   * This event tracks when a custom brand draft is created and saved for the first time.
   *
   * Owner: Nick West
   *
   * @param options Amplitude event options.
   */
  brandDraftCreated(
    options?: EventOptions,
  ) {
    return this.track(new BrandDraftCreated(), options);
  }

  /**
   * Brand Draft Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Brand%20Draft%20Updated)
   *
   * This event tracks when a custom brand draft is updated after initial save.
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. brand_id)
   * @param options Amplitude event options.
   */
  brandDraftUpdated(
    properties: BrandDraftUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandDraftUpdated(properties), options);
  }

  /**
   * Brand Logo Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Brand%20Logo%20Uploaded)
   *
   * Event to track when a user uploads a brand logo for their custom branding.
   *
   * Owner: Nick West
   *
   * @param options Amplitude event options.
   */
  brandLogoUploaded(
    options?: EventOptions,
  ) {
    return this.track(new BrandLogoUploaded(), options);
  }

  /**
   * Brand Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Brand%20Published)
   *
   * Event to track when a user has published a custom brand.
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. brand_id)
   * @param options Amplitude event options.
   */
  brandPublished(
    properties: BrandPublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandPublished(properties), options);
  }

  /**
   * Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Button%20Clicked)
   *
   * This event tracks when a user clicks on a button, indicating engagement with a specific feature or action
   *
   * Owner: Dane Roberts
   *
   * @param properties The event's properties (e.g. button_location)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * Cancel Import Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Cancel%20Import%20Button%20Clicked)
   *
   * Event to track when a user clicks the cancel import button
   *
   * Owner: Matt Roden
   *
   * @param options Amplitude event options.
   */
  cancelImportButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new CancelImportButtonClicked(), options);
  }

  /**
   * Co-sell Connected
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Co-sell%20Connected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  coSellConnected(
    properties: CoSellConnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CoSellConnected(properties), options);
  }

  /**
   * Co-sell Opportunities Bulk Linked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Co-sell%20Opportunities%20Bulk%20Linked)
   *
   * Event to track bulk linking between co-sell opportunities and salesforce opportunities
   *
   * @param properties The event's properties (e.g. button_location)
   * @param options Amplitude event options.
   */
  coSellOpportunitiesBulkLinked(
    properties: CoSellOpportunitiesBulkLinkedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CoSellOpportunitiesBulkLinked(properties), options);
  }

  /**
   * Custom Role Assigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Role%20Assigned)
   *
   * Event to track the assignment of custom roles to users within the user management system
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. custom_role_id)
   * @param options Amplitude event options.
   */
  customRoleAssigned(
    properties: CustomRoleAssignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomRoleAssigned(properties), options);
  }

  /**
   * Custom Role Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Role%20Copied)
   *
   * Event to track the copying of custom roles within the user management system
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. custom_role_id)
   * @param options Amplitude event options.
   */
  customRoleCopied(
    properties: CustomRoleCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomRoleCopied(properties), options);
  }

  /**
   * Custom Role Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Role%20Created)
   *
   * Event to track the creation of custom roles within the user management system
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. custom_role_id)
   * @param options Amplitude event options.
   */
  customRoleCreated(
    properties: CustomRoleCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomRoleCreated(properties), options);
  }

  /**
   * Custom Role Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Role%20Deleted)
   *
   * Event to track the deleting of custom roles within the user management system
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. custom_role_id)
   * @param options Amplitude event options.
   */
  customRoleDeleted(
    properties: CustomRoleDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomRoleDeleted(properties), options);
  }

  /**
   * Custom Role Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Role%20Edited)
   *
   * Event to track the editing of custom roles within the user management system
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. custom_role_id)
   * @param options Amplitude event options.
   */
  customRoleEdited(
    properties: CustomRoleEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomRoleEdited(properties), options);
  }

  /**
   * Custom Text Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Custom%20Text%20Edited)
   *
   * Event to track when users edit custom text in the custom brand section of Notifications.
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. brand_id)
   * @param options Amplitude event options.
   */
  customTextEdited(
    properties?: CustomTextEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomTextEdited(properties), options);
  }

  /**
   * Data Filter Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Data%20Filter%20Applied)
   *
   * This event tracks when a user applies a filter in the reporting section of the platform
   *
   * Owner: alan.hedrick@tackle.io
   *
   * @param properties The event's properties (e.g. filter_type)
   * @param options Amplitude event options.
   */
  dataFilterApplied(
    properties: DataFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataFilterApplied(properties), options);
  }

  /**
   * Export Scored Accounts
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Export%20Scored%20Accounts)
   *
   * This event tracks how accounts are being exported from Prospect.
   *
   * @param properties The event's properties (e.g. export_filters)
   * @param options Amplitude event options.
   */
  exportScoredAccounts(
    properties: ExportScoredAccountsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportScoredAccounts(properties), options);
  }

  /**
   * Import Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Import%20Button%20Clicked)
   *
   * Event to track when users click on the import button within the platform
   *
   * Owner: Matt Roden
   *
   * @param properties The event's properties (e.g. total_aws_attempted_count)
   * @param options Amplitude event options.
   */
  importButtonClicked(
    properties: ImportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImportButtonClicked(properties), options);
  }

  /**
   * Import From Marketplace Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Import%20From%20Marketplace%20Button%20Clicked)
   *
   * Event to track when users click the "Import From Marketplace" button within the Listing Management feature that just opens up the listing import modal.
   *
   * Owner: Matt Roden
   *
   * @param options Amplitude event options.
   */
  importFromMarketplaceButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new ImportFromMarketplaceButtonClicked(), options);
  }

  /**
   * Inbound Received
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Inbound%20Received)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  inboundReceived(
    properties: InboundReceivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InboundReceived(properties), options);
  }

  /**
   * Integration Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Integration%20Saved)
   *
   * This event tracks when a user hits the Save button on an integration within the Tackle platform.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  integrationSaved(
    properties: IntegrationSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSaved(properties), options);
  }

  /**
   * Integration Tested
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Integration%20Tested)
   *
   * This event tracks the testing of integrations within our platform (when the Test Integration Button is clicked). It provides insights into the performance and reliability of integrations, helping us identify any issues or improvements needed
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  integrationTested(
    properties: IntegrationTestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationTested(properties), options);
  }

  /**
   * Integration Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Integration%20Toggled)
   *
   * This event tracks when a user toggles an integration on or off within the platform
   *
   * @param properties The event's properties (e.g. toggle)
   * @param options Amplitude event options.
   */
  integrationToggled(
    properties: IntegrationToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationToggled(properties), options);
  }

  /**
   * Listing Preview Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Listing%20Preview%20Viewed)
   *
   * This event tracks when a user clicks the Listing Preview button in a single listing page
   *
   * Owner: Jaeriah Tay
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  listingPreviewViewed(
    properties: ListingPreviewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingPreviewViewed(properties), options);
  }

  /**
   * Listing Registration Preview Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Listing%20Registration%20Preview%20Viewed)
   *
   * This event tracks when a user clicks the listing registration preview button in a single listing page
   *
   * Owner: Jaeriah Tay
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  listingRegistrationPreviewViewed(
    properties: ListingRegistrationPreviewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingRegistrationPreviewViewed(properties), options);
  }

  /**
   * Marketplace Insights Card Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Marketplace%20Insights%20Card%20Closed)
   *
   * This event tracks when a user clicks on the "close" button in the Marketplace Insights card of the prospect page, and dismisses the card.
   *
   * Owner: Nathan Bland
   *
   * @param options Amplitude event options.
   */
  marketplaceInsightsCardClosed(
    options?: EventOptions,
  ) {
    return this.track(new MarketplaceInsightsCardClosed(), options);
  }

  /**
   * Marketplace Insights Listing Requirements Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Marketplace%20Insights%20Listing%20Requirements%20Clicked)
   *
   * This event tracks when a user clicks on the "listing requirements" button in the Marketplace Insights card of the prospect page
   *
   * Owner: Nathan Bland
   *
   * @param options Amplitude event options.
   */
  marketplaceInsightsListingRequirementsClicked(
    options?: EventOptions,
  ) {
    return this.track(new MarketplaceInsightsListingRequirementsClicked(), options);
  }

  /**
   * Marketplace Insights View Accounts Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Marketplace%20Insights%20View%20Accounts%20Clicked)
   *
   * This event tracks when a user clicks the "View Accounts" button in the marketplace insights card on the prospect page.
   *
   * Owner: Nathan Bland
   *
   * @param options Amplitude event options.
   */
  marketplaceInsightsViewAccountsClicked(
    options?: EventOptions,
  ) {
    return this.track(new MarketplaceInsightsViewAccountsClicked(), options);
  }

  /**
   * Offer Extended
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Offer%20Extended)
   *
   * This event tracks when an offer is extended to a buyer. Specifically when "Send purchase instructions" is clicked in the UI and the request is successful
   *
   * Owner: Sam White
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  offerExtended(
    properties: OfferExtendedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OfferExtended(properties), options);
  }

  /**
   * Offer Page Related Item Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Offer%20Page%20Related%20Item%20Clicked)
   *
   * Indicates when an item in the "related Items" section of a private offer review screen is clicked.
   *
   * Owner: Nathan Bland
   *
   * @param properties The event's properties (e.g. related_item_clicked)
   * @param options Amplitude event options.
   */
  offerPageRelatedItemClicked(
    properties: OfferPageRelatedItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OfferPageRelatedItemClicked(properties), options);
  }

  /**
   * Offers Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Offers%20Clicked)
   *
   * A touch point to let us know if a user has navigated to offers from the prospect account details view
   *
   * @param properties The event's properties (e.g. marketplace)
   * @param options Amplitude event options.
   */
  offersClicked(
    properties: OffersClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OffersClicked(properties), options);
  }

  /**
   * Offers Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Offers%20Page%20Viewed)
   *
   * Event triggered when users visit any of the three Offers pages: list view (`list_offers`), create view (`new_offer`), single private offer view (`review_offer`).
   *
   * Owner: Dylan
   *
   * @param properties The event's properties (e.g. abo_linked_offer_ref)
   * @param options Amplitude event options.
   */
  offersPageViewed(
    properties?: OffersPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OffersPageViewed(properties), options);
  }

  /**
   * Outbound Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Outbound%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cloud)
   * @param options Amplitude event options.
   */
  outboundSubmitted(
    properties: OutboundSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OutboundSubmitted(properties), options);
  }

  /**
   * Prospect Page Filters Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Prospect%20Page%20Filters%20Applied)
   *
   * A filter set has been applied to the prospect page. This covers changing results from filters being removed as well.
   *
   * Owner: Nathan Bland
   *
   * @param properties The event's properties (e.g. file_filter)
   * @param options Amplitude event options.
   */
  prospectPageFiltersApplied(
    properties: ProspectPageFiltersAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProspectPageFiltersApplied(properties), options);
  }

  /**
   * Refine Account List
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Refine%20Account%20List)
   *
   * This event tracks how users interact with their list of scored accounts within Tackle Prospect.
   *
   * @param properties The event's properties (e.g. filter_ name)
   * @param options Amplitude event options.
   */
  refineAccountList(
    properties: RefineAccountListProperties,
    options?: EventOptions,
  ) {
    return this.track(new RefineAccountList(properties), options);
  }

  /**
   * Report Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Report%20Downloaded)
   *
   * This event tracks when a user downloads a report from Insights, Disbursements, or Datafeeds
   *
   * Owner: alan.hedrick@tackle.io
   *
   * @param properties The event's properties (e.g. child_report_name)
   * @param options Amplitude event options.
   */
  reportDownloaded(
    properties: ReportDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportDownloaded(properties), options);
  }

  /**
   * Resource Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Resource%20Clicked)
   *
   * This event tracks when a user clicks on a resource, providing insights into user engagement with specific external resources on the platform, such as Tackle Docs
   *
   * Owner: Dane Roberts
   *
   * @param properties The event's properties (e.g. resource_external_url)
   * @param options Amplitude event options.
   */
  resourceClicked(
    properties: ResourceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResourceClicked(properties), options);
  }

  /**
   * Roles and Permissions Tab Visited
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Roles%20and%20Permissions%20Tab%20Visited)
   *
   * Event triggered when a user visits the Roles and Permissions tab within the user management page
   *
   * Owner: Nick West
   *
   * @param options Amplitude event options.
   */
  rolesAndPermissionsTabVisited(
    options?: EventOptions,
  ) {
    return this.track(new RolesAndPermissionsTabVisited(), options);
  }

  /**
   * session_end
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/session_end)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sessionEnd(
    options?: EventOptions,
  ) {
    return this.track(new SessionEnd(), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/session_start)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }

  /**
   * Sign Up Step Completed - Company Information
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Sign%20Up%20Step%20Completed%20-%20Company%20Information)
   *
   * This event tracks the completion of the company information step during the sign-up process for new users.
   *
   * Owner: Dane Roberts
   *
   * @param options Amplitude event options.
   */
  signUpStepCompletedCompanyInformation(
    options?: EventOptions,
  ) {
    return this.track(new SignUpStepCompletedCompanyInformation(), options);
  }

  /**
   * Sign Up Step Completed - Password
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Sign%20Up%20Step%20Completed%20-%20Password)
   *
   * This event tracks the completion of the password step during the sign-up process for new users.
   *
   * Owner: Dane Roberts
   *
   * @param options Amplitude event options.
   */
  signUpStepCompletedPassword(
    options?: EventOptions,
  ) {
    return this.track(new SignUpStepCompletedPassword(), options);
  }

  /**
   * Sign Up Step Completed - Personal Information
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Sign%20Up%20Step%20Completed%20-%20Personal%20Information)
   *
   * This event tracks the completion of the personal information step during the sign-up process for new users.
   *
   * Owner: Dane Roberts
   *
   * @param options Amplitude event options.
   */
  signUpStepCompletedPersonalInformation(
    options?: EventOptions,
  ) {
    return this.track(new SignUpStepCompletedPersonalInformation(), options);
  }

  /**
   * SSO Config Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/SSO%20Config%20Saved)
   *
   * This event tracks when a user saves changes to their Single Sign-On (SSO) configuration in the User Management section
   *
   * @param properties The event's properties (e.g. idp)
   * @param options Amplitude event options.
   */
  ssoConfigSaved(
    properties?: SsoConfigSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SsoConfigSaved(properties), options);
  }

  /**
   * Test Email Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Test%20Email%20Sent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. brand_id)
   * @param options Amplitude event options.
   */
  testEmailSent(
    properties: TestEmailSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new TestEmailSent(properties), options);
  }

  /**
   * User Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/User%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. user_id)
   * @param options Amplitude event options.
   */
  userDeleted(
    properties: UserDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserDeleted(properties), options);
  }

  /**
   * User Invited
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/User%20Invited)
   *
   * This event tracks when a user is invited to join the platform through the user management system
   *
   * @param properties The event's properties (e.g. user_id)
   * @param options Amplitude event options.
   */
  userInvited(
    properties: UserInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserInvited(properties), options);
  }

  /**
   * User Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/User%20Modified)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. previous_user_role)
   * @param options Amplitude event options.
   */
  userModified(
    properties: UserModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserModified(properties), options);
  }

  /**
   * User Signed Up
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/User%20Signed%20Up)
   *
   * This event tracks when a user successfully signs up for our platform during the self-service sign up flow
   *
   * Owner: Dane Roberts
   *
   * @param properties The event's properties (e.g. user_id)
   * @param options Amplitude event options.
   */
  userSignedUp(
    properties: UserSignedUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserSignedUp(properties), options);
  }

  /**
   * Vendor Account Switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Vendor%20Account%20Switched)
   *
   * This event tracks when a user switches between vendor accounts in Downstream
   *
   * Owner: Nick West
   *
   * @param properties The event's properties (e.g. new_vendor_id)
   * @param options Amplitude event options.
   */
  vendorAccountSwitched(
    properties: VendorAccountSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VendorAccountSwitched(properties), options);
  }

  /**
   * Vendor Environment Switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Vendor%20Environment%20Switched)
   *
   * This event tracks when a user switches between environment accounts in Downstream. (Test environments)
   *
   * Owner: Sam White
   *
   * @param properties The event's properties (e.g. new_vendor_id)
   * @param options Amplitude event options.
   */
  vendorEnvironmentSwitched(
    properties: VendorEnvironmentSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VendorEnvironmentSwitched(properties), options);
  }

  /**
   * Video Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/tackle/Tackle%20Platform/events/main/latest/Video%20Played)
   *
   * This event tracks when a video is played by a user, providing insights into user engagement with video content
   *
   * Owner: Dane Roberts
   *
   * @param properties The event's properties (e.g. video_id)
   * @param options Amplitude event options.
   */
  videoPlayed(
    properties: VideoPlayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VideoPlayed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;

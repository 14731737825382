import { useMemo } from 'react';
import AceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityForm';
import { DisplayCloudType } from '../../types/enums';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { OpportunityProvider } from './providers/OpportunityProvider';

const renderForm = (cloud) => {
  switch (cloud) {
    case DisplayCloudType.AWS:
    default:
      return <AceOpportunityForm />;
  }
};

const UnifiedOpportunityForm = ({ match }) => {
  const cloud = useMemo(
    () => match.params.cloud.split('?')[0],
    [match.params.cloud],
  );

  return (
    <UnifiedOpportunityFormContainer>
      {renderForm(cloud)}
    </UnifiedOpportunityFormContainer>
  );
};

export const UnifiedOpportunityFormContainer = ({ children }) => {
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <OpportunityProvider>{children}</OpportunityProvider>
    </ErrorBoundary>
  );
};
export default UnifiedOpportunityForm;

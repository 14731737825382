import * as Yup from 'yup';
import {
  EMAIL_ERROR_MSG,
  EMAIL_REGEX,
  PHONE_NUMBER_MSG,
  PHONE_NUMBER_REGEX,
  REQUIRED,
  WEBSITE_ERROR_MSG,
  WEBSITE_REGEX,
} from '../FormValidationConstants';
import {
  AceNotNeededFromCloud,
  AceOpportunityTypeEnum,
  CountryEnum,
  OpportunityIndustryEnum,
  OpportunityYesNoEnum,
} from 'packages/cosell/src/types/enums';

export const aceOpportunityFormValidationSchema = Yup.object().shape({
  nextStep: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),

  // Section 1
  projectTitle: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .required(REQUIRED)
    .nullable(true),
  crm_id: Yup.string().required(REQUIRED).nullable(),
  // Sales activities is required only when the partner needs support from AWS.
  salesActivities: Yup.string()
    .when('partnerNeedType', {
      is: (partnerNeedType) =>
        partnerNeedType !==
        AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    })
    .nullable(true),
  expectedMonthlyRevenue: Yup.string()
    .required(REQUIRED)
    .test('is larger than 0', 'Must be larger than $0.00', (monthlyRevenue) => {
      return parseInt(monthlyRevenue) > 0;
    })
    .nullable(true),
  targetCloseDate: Yup.string()
    .test(
      'is future date',
      "Should be later than today's date",
      (selectedDate) => {
        const today = new Date();
        const selDate = new Date(selectedDate);
        return selDate > today;
      },
    )
    .required(REQUIRED)
    .nullable(true),
  opportunityType: Yup.string().required(REQUIRED).nullable(true),
  solutions: Yup.string().required(REQUIRED).nullable(true),
  salesStage: Yup.string().nullable(true),
  parentOppId: Yup.string()
    .when('opportunityType', {
      is: (oppType) => oppType === AceOpportunityTypeEnum.FLAT_RENEWAL,
      then: Yup.string(),
      otherwise: Yup.string(),
    })
    .nullable(true),

  // Section 2
  customerCompanyName: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .required(REQUIRED)
    .nullable(true),
  industry: Yup.string().required(REQUIRED).nullable(true),
  industryOther: Yup.string()
    .when('industry', {
      is: (industry) => industry === OpportunityIndustryEnum.OTHER,
      then: Yup.string(),
      otherwise: Yup.string(),
    })
    .nullable(true),
  nationalSecurityRelated: Yup.string()
    .when(['industry', 'country'], {
      is: (industry, country) =>
        industry === OpportunityIndustryEnum.GOVERNMENT &&
        country === CountryEnum.UNITED_STATES,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),

  state: Yup.string()
    .max(255, 'Must be less than 255 characters.')
    .when('country', {
      is: (country) => country === CountryEnum.UNITED_STATES,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),
  postalCode: Yup.string()
    .min(1, 'Must be at least 1 character')
    .max(20, 'Must be less than 20 characters')
    .required(REQUIRED)
    .nullable(true),
  customerWebsite: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .matches(WEBSITE_REGEX, WEBSITE_ERROR_MSG)
    .required(REQUIRED)
    .nullable(true),
  dunsNumber: Yup.string().nullable(true),

  // Section 3
  customerFirstName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  customerLastName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  customerEmail: Yup.string().email(EMAIL_ERROR_MSG).nullable(true),
  customerPhone: Yup.string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_MSG)
    .nullable(true),

  // Section 4
  primaryContactFirstName: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),
  primaryContactLastName: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),
  primaryContactEmail: Yup.string().email(EMAIL_ERROR_MSG).nullable(true),
  primaryContactPhone: Yup.string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_MSG)
    .nullable(true),
  opportunityOwnerEmail: Yup.string()
    .matches(EMAIL_REGEX, EMAIL_ERROR_MSG)
    .nullable(true),

  // Section 5
  IsOppFromMarketingActivity: Yup.string().required(REQUIRED).nullable(true),
  awsSFCampaignName: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),
  isMarketingDevelopmentFunded: Yup.string()
    .when('IsOppFromMarketingActivity', {
      is: (IsOppFromMarketingActivity) =>
        IsOppFromMarketingActivity === OpportunityYesNoEnum.YES,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),

  // Section 6
  deliveryModel: Yup.string().required(REQUIRED).nullable(true),
  useCase: Yup.string().required(REQUIRED).nullable(true),
  partnerNeedType: Yup.string().required(REQUIRED).nullable(true),
  projectDescription: Yup.string()
    .min(20, 'Must be at least 20 characters')
    .max(32000, 'Must be less than 32000 characters')
    .required(REQUIRED)
    .nullable(true),
  additionalComments: Yup.string()
    .max(32000, 'Must be less than 32000 characters')
    .nullable(true),
  campaignName: Yup.string().nullable(true),

  offers: Yup.string().nullable(true),
});

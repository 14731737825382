import { AceOpportunityFormValues } from 'pages/CoSell/components/OpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  OpportunityRequest,
  OpportunityRequestCustomer,
  OpportunityRequestDetail,
  OpportunityRequestMember,
  OpportunityRequestPricing,
} from 'pages/CoSell/types/requests/OpportunityRequest';
import emptyStringToNull from 'utils/emptyStringToNull';
import { convertYesNoToBoolean } from '../convertYesNoToBoolean';
import {
  AceOpportunityPartnerAcceptanceStatusEnum,
  CoSellCloudType,
  OpportunityIndustryEnum,
  OpportunityMemberTitleEnum,
  OpportunityMemberTypeEnum,
} from 'pages/CoSell/types/enums';
import { solutionsFormInitialValueToApiFormat } from '../formatSolutions';

// TODO: since we only have one request type now,
// we need to combine convertAceFormValuesToCreateRequest and convertAceFormValuesToUpdateRequest - LM 05/08/2024

export const getDetails = (
  values: AceOpportunityFormValues,
): OpportunityRequestDetail => {
  const industries = values.industry
    ? [
        {
          id: values.industry,
          type: OpportunityIndustryEnum[values.industry],
        },
      ]
    : null;
  const useCases = values.useCase
    ? [
        // not using enum due to v13 and v14 differences. update once v13 is deprecated 4/17/24
        {
          id: values.useCase,
          type: values.useCase,
        },
      ]
    : null;

  const salesActivities = values?.salesActivities
    ? values?.salesActivities?.split(';')
    : null;

  // TODO: Validate we should just pass the externalId and the name
  const solutions = values?.solutions
    ? solutionsFormInitialValueToApiFormat(values?.solutions)
    : null;

  const offers = values?.offers ? values?.offers?.split(';') : null;

  const details = {
    campaignName: emptyStringToNull(values?.campaignName),
    contractVehicle: emptyStringToNull(values?.contractVehicle),
    deliveryModel: emptyStringToNull(values.deliveryModel),
    industries: industries,
    industryOther: emptyStringToNull(values?.industryOther),
    isFromMarketingActivity:
      values.IsOppFromMarketingActivity === 'Yes' ? true : false,
    isMarketingDevelopmentFunded: convertYesNoToBoolean(
      values.isMarketingDevelopmentFunded,
    ),
    marketingActivityChannel: emptyStringToNull(
      values?.marketingActivityChannel,
    ),
    marketingActivityUseCase: emptyStringToNull(
      values?.marketingActivityUsecase,
    ),
    marketingCampaignName: emptyStringToNull(values?.awsSFCampaignName),
    nationalSecurityRelated: convertYesNoToBoolean(
      values?.nationalSecurityRelated,
    ),
    needFromCloud: emptyStringToNull(values.partnerNeedType),
    needFromCloudOther: emptyStringToNull(values?.partnerNeedTypeOther),
    notes: emptyStringToNull(values?.additionalComments),
    offers: offers,
    rfxSolicitationNumber: emptyStringToNull(values?.rfxSolicitationNumber),
    useCases: useCases,
    salesActivities: salesActivities,
    solutions: solutions,
    status: emptyStringToNull(values?.salesStage),
  };
  return details;
};

export const getPricing = (
  values: AceOpportunityFormValues,
): OpportunityRequestPricing => {
  const pricing: OpportunityRequestPricing = {
    dealValueMonthly: values?.expectedMonthlyRevenue,
  };

  return pricing;
};

export const getCustomer = (
  values: AceOpportunityFormValues,
): OpportunityRequestCustomer => {
  const customer: OpportunityRequestCustomer = {
    companyName: values.customerCompanyName,
    website: emptyStringToNull(values?.customerWebsite),
    country: values.country,
    dunsNumber: emptyStringToNull(values?.dunsNumber),
    postalCode: emptyStringToNull(values?.postalCode),
    state: emptyStringToNull(values?.state),
  };

  return customer;
};

export const getMembers = (
  values: AceOpportunityFormValues,
): OpportunityRequestMember[] => {
  const membersList = [
    {
      firstName: values?.primaryContactFirstName,
      lastName: values?.primaryContactLastName,
      email: values?.primaryContactEmail,
      phone: values?.primaryContactPhone,
      title: OpportunityMemberTitleEnum.PRIMARY_CONTACT,
      type: OpportunityMemberTypeEnum.VENDOR,
    },
    {
      email: values.opportunityOwnerEmail,
      title: OpportunityMemberTitleEnum.OPPORTUNITY_OWNER,
      type: OpportunityMemberTypeEnum.VENDOR,
    },
    {
      firstName: values?.customerFirstName,
      lastName: values?.customerLastName,
      email: values?.customerEmail,
      phone: values?.customerPhone,
      title: OpportunityMemberTitleEnum.CUSTOMER_TEAM,
      type: OpportunityMemberTypeEnum.CUSTOMER,
    },
  ];
  return membersList;
};

export const convertAceFormValuesToCreateRequest = (
  values: AceOpportunityFormValues,
  vendorId: string,
): OpportunityRequest => {
  return {
    accepted:
      values.partnerAcceptanceStatus === undefined
        ? null
        : values.partnerAcceptanceStatus ===
          AceOpportunityPartnerAcceptanceStatusEnum.ACCEPTED,
    cloud: CoSellCloudType.ACE,
    targetCloseDate: values?.targetCloseDate,
    description: emptyStringToNull(values?.projectDescription),
    externalCrmCosellId: emptyStringToNull(values?.crm_id),
    externalSecondaryId: emptyStringToNull(values?.parentOppId),
    status: values.status,
    title: emptyStringToNull(values?.projectTitle),
    type: emptyStringToNull(values?.opportunityType),
    vendorId: vendorId,
    customer: getCustomer(values),
    details: getDetails(values),
    pricing: getPricing(values),
    members: getMembers(values),
  };
};

import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from 'vendor/material';
import useStyles from './RadioFormField.styles';
import { InformationOutline } from 'mdi-material-ui';
import { useFormikContext } from 'formik';
import { OpportunityYesNoEnum } from 'pages/CoSell/types/enums';
import { Label } from 'pages/CoSell/components/Label/Label';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

interface RadioFormFieldProps {
  label: string;
  name: string;
  id: string;
  required?: boolean;
  tooltipText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  radioLabelYes?: string;
  radioLabelNo?: string;
  radioValueYes?: any;
  radioValueNo?: any;
}

export const RadioFormField: React.FC<RadioFormFieldProps> = ({
  label,
  name,
  id,
  tooltipText,
  required,
  onChange,
  disabled,
  radioLabelYes = 'Yes',
  radioLabelNo = 'No',
  radioValueYes = OpportunityYesNoEnum.YES,
  radioValueNo = OpportunityYesNoEnum.NO,
}) => {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    setFieldValue(name, event.target.value);
    setFieldTouched(name, true);
  };

  return (
    <FormControl
      aria-disabled={disabled}
      disabled={disabled}
      error={touched[name] && errors[name] && !values?.[name]}
      id={id}
      data-id={id}
    >
      <FormLabel className={classes.formLabel} data-id={`${id}Label`}>
        <Label label={label} required={required} />
        {tooltipText && (
          <Tooltip className={classes.tooltip} title={tooltipText}>
            <InformationOutline fontSize="small" />
          </Tooltip>
        )}
      </FormLabel>
      <RadioGroup
        name={id}
        className={classes.radioGroup}
        onChange={handleFieldChange}
        value={values?.[name]}
      >
        <FormControlLabel
          role={'presentation'}
          data-testid={`${id}_yes`}
          id={`${id}_yes`}
          data-id={`${id}_yes`}
          name={`${id}_yes`}
          label={radioLabelYes}
          value={radioValueYes}
          control={<Radio />}
        />
        <FormControlLabel
          data-testid={`${id}_no`}
          role={'presentation'}
          id={`${id}_no`}
          data-id={`${id}_no`}
          name={`${id}_no`}
          label={radioLabelNo}
          value={radioValueNo}
          control={<Radio />}
        />
      </RadioGroup>
      {touched[name] && errors[name] && !values?.[name] ? (
        <FormHelperText>{errors[name]}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

import { Box, Tooltip } from 'vendor/material';
import { ComboBox } from '@tackle-io/platform-ui';
import { InformationOutline } from 'mdi-material-ui';
import { useFormikContext } from 'formik';
import { StandardEnum } from 'pages/CoSell/types';
import React, { useMemo } from 'react';
import { Label } from 'pages/CoSell/components/Label/Label';
import useStyles from './SingleSelectFormField.styles';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

const DEFAULT_OPTION = '--None--';

type SelectFormFieldOption = {
  title: string;
  value: string | number;
};

interface SingleSelectFormFieldProps {
  fieldId: string;
  optionsList: StandardEnum<string> | SelectFormFieldOption[];
  label: string;
  disabled?: boolean;
  filterSelectedOptions?: boolean;
  tooltipText?: string;
  required?: boolean;
  dataId?: string;
}

export const SingleSelectFormField: React.FC<SingleSelectFormFieldProps> = ({
  disabled,
  fieldId,
  label,
  optionsList,
  tooltipText,
  required,
  dataId = null,
}) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const classes = useStyles();

  // It's important to memoize options here because our multiselect field
  // determines options and selected option equality by reference.
  const options: SelectFormFieldOption[] = useMemo(() => {
    if (optionsList instanceof Array) {
      return optionsList;
    }
    return Object.values(optionsList).map((x) => ({
      title: x,
      value: x,
    }));
  }, [optionsList]);

  const value = values?.[fieldId]
    ? options.find((x) => x.value === values?.[fieldId])
    : null;

  const handleSingleSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedOption: SelectFormFieldOption,
  ) => {
    setFieldTouched(fieldId, true);
    setFieldValue(fieldId, selectedOption?.value);
  };

  const textFieldLabel = tooltipText ? (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${fieldId}Label`}
    >
      <Label label={label} required={required} />
      <Tooltip
        className={classes.tooltip}
        title={tooltipText}
        placement="top-start"
      >
        <InformationOutline fontSize="small" />
      </Tooltip>
    </Box>
  ) : (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${fieldId}Label`}
    >
      <Label label={label} required={required} />
    </Box>
  );

  return (
    <ComboBox
      disabled={disabled}
      hasError={touched[fieldId] && errors?.[fieldId]}
      id={fieldId}
      label={textFieldLabel}
      multiple={false}
      onChange={handleSingleSelectChange}
      options={options}
      value={value}
      placeholder={DEFAULT_OPTION}
      description={touched[fieldId] && errors?.[fieldId]}
    />
  );
};

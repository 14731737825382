import { ComboBox } from '@tackle-io/platform-ui';
import { useFormikContext } from 'formik';
import { StandardEnum } from 'pages/CoSell/types';
import React, { useMemo } from 'react';
import { SelectFormFieldOption } from '../../types';
import { Label } from 'pages/CoSell/components/Label/Label';
import { Box } from 'vendor/material';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

const DEFAULT_OPTION = '--None--';

interface MultiSelectFormFieldProps {
  // Optional string that indicates how the selected options may be joined (comma, semicolon, etc.) for database storage according to a given cloud provider's requirements:
  delimiter?: string;
  disabled?: boolean;
  fieldId: string;
  filterSelectedOptions?: boolean;
  label: string;
  optionsEnum: StandardEnum<string>;
  placeholder?: string;
  required: boolean;
  dataId?: string;
}

export const MultiSelectFormField: React.FC<MultiSelectFormFieldProps> = ({
  delimiter,
  disabled,
  fieldId,
  filterSelectedOptions,
  label,
  optionsEnum,
  placeholder,
  required,
  dataId = null,
}) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<UnifiedOpportunityFormValues>();

  // It's important to memoize options here because our multiselct field
  // determines options and selected option equality by reference.
  const options: SelectFormFieldOption[] = useMemo(
    () =>
      Object.values(optionsEnum).map((x) => ({
        title: x,
        value: x,
      })),
    [optionsEnum],
  );

  // The values for the multi-select field may be stored in databases differently across cloud providers. For ACE, multi-select values are stored as a semicolon-separated string (e.g., "val1;val2;val3"); Therefore, we need to transform the Option[] type to that format for successful form submission. The code for this is small but could be confusing without this comment's context, hence, it has been extracted out into a clear, readable function.
  const transformDeliminatedStringToOptions = (
    valuesFromSelectedOptions: (string | number)[],
  ): string => {
    if (valuesFromSelectedOptions?.length > 0) {
      return valuesFromSelectedOptions.join(delimiter).toString();
    }
    return null;
  };

  const handleMultiSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedOptions: SelectFormFieldOption[],
  ) => {
    setFieldTouched(fieldId, true);

    const valuesFromSelectedOptions = selectedOptions?.map(
      (option) => option.value,
    );

    if (delimiter) {
      setFieldValue(
        fieldId,
        transformDeliminatedStringToOptions(valuesFromSelectedOptions),
      );
    } else {
      setFieldValue(fieldId, valuesFromSelectedOptions);
    }
  };

  const value = options?.filter((x) => values?.[fieldId]?.includes(x.value));
  const comboBoxLabel = dataId ? (
    <Box data-id={dataId}>
      <Label label={label} required={required} />
    </Box>
  ) : (
    <Label label={label} required={required} />
  );
  return (
    <ComboBox
      disabled={disabled}
      hasError={touched[fieldId] && errors?.[fieldId]}
      filterSelectedOptions={filterSelectedOptions}
      id={fieldId}
      label={comboBoxLabel}
      multiple={true}
      onChange={handleMultiSelectChange}
      options={options}
      value={value}
      placeholder={placeholder ? placeholder : DEFAULT_OPTION}
      description={touched[fieldId] && errors?.[fieldId]}
    />
  );
};

import ky from 'ky';
import { get } from 'lodash';
import { authStore } from '../../../stores/Auth';
import { reportError } from 'utils/monitor';
import {
  ErrorResponse,
  DashboardOpportunitiesResponse,
  OpportunityCSVResponse,
} from '../types';
import { OpportunityResponse } from '../types/responses/OpportunityResponse';
import { OpportunityRequest } from '../types/requests/OpportunityRequest';
import { OpportunityStatusEnum } from '../types/enums/OpportunityStatusEnum';

// our calls to create/update ACE opportunities are rather expensive
// unfortunately, the speed at which ACE processes the opportunities we send it is out of our control
// our API service waits up to 20 seconds for ACE to process our opportunities, we need to provide enough time for that to complete
const COSELL_API_TIMEOUT = 25000;
const TIMEOUT_STATUS_CODE = 408;

const legacyGetAuthToken = async () => authStore.getToken();

const getErrorResponse = async (error) => {
  let errorResponse: ErrorResponse = {
    httpStatusCode: get(error, 'response.status', 500),
    message: get(error, 'message', 'An error was encountered'),
    detail: get(error, 'message', 'An error was encountered'),
  };
  if (error instanceof ky.HTTPError) {
    const errorJson = await error.response.json();
    errorResponse.detail = errorJson.detail;
  } else if (error instanceof ky.TimeoutError) {
    errorResponse.httpStatusCode = TIMEOUT_STATUS_CODE;
  }
  return errorResponse;
};

const makeCosellApiClient = (getAccessToken) => {
  const cosellApiConfig = {
    prefixUrl: process.env.REACT_APP_TACKLE_CO_SELL_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = await getAccessToken();
          request.headers.set('Authorization', `Bearer ${token}`);
          return request;
        },
      ],
    },
  };
  const cosellHttpClient = ky.create(cosellApiConfig);

  const cosellClient = {
    async getOpportunitiesList(
      vendorId: string,
      searchFilters?: string,
      searchTerm?: string,
    ): Promise<DashboardOpportunitiesResponse> {
      let queryParams = '';

      if (searchFilters) {
        const searchParamsString = new URLSearchParams(
          searchFilters,
        ).toString();
        queryParams = `?${searchParamsString}`;
      }

      if (searchTerm) {
        const searchTermString = `search_term=${searchTerm}`;
        queryParams = queryParams
          ? `${queryParams}&${searchTermString}`
          : `?${searchTermString}`;
      }

      try {
        const result = await cosellHttpClient
          .get(`api/v2/opportunity/${vendorId}${queryParams}`)
          .json();
        return result as DashboardOpportunitiesResponse;
      } catch (error) {
        reportError(error);
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async getAllOpportunitiesList(
      vendorId: string,
      searchFilters?: string,
      searchTerm?: string,
    ): Promise<OpportunityCSVResponse> {
      let queryParams = '';
      if (searchFilters) {
        const searchParamsString = new URLSearchParams(
          searchFilters,
        ).toString();
        queryParams = `?${searchParamsString}`;
      }
      if (searchTerm) {
        const searchTermString = `search_term=${searchTerm}`;
        queryParams = queryParams
          ? `${queryParams}&${searchTermString}`
          : `?${searchTermString}`;
      }

      try {
        const result = await cosellHttpClient
          .get(`api/v2/opportunity/${vendorId}/csv${queryParams}`, {
            timeout: COSELL_API_TIMEOUT,
          })
          .json();
        return result as OpportunityCSVResponse;
      } catch (error) {
        reportError(error);
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
        throw new Error('An error was encountered');
      }
    },

    async getOpportunityById(
      vendorId: string,
      cloud: string,
      opportunityId: string,
    ): Promise<OpportunityResponse> {
      return await cosellHttpClient
        .get(
          `api/v2/opportunity/${vendorId}/cloud/${cloud}/id/${opportunityId}`,
        )
        .then(async (response) => {
          const jsonResponse = await response.json();
          return jsonResponse;
        })
        .catch(async (error) => {
          reportError(error);
          const err = await getErrorResponse(error);
          throw err;
        });
    },

    async createOpportunity(
      requestBody: OpportunityRequest,
    ): Promise<any | ErrorResponse> {
      const { cloud, vendorId } = requestBody;
      try {
        const result = await cosellHttpClient
          .post(`api/v2/opportunity/${vendorId}/cloud/${cloud}`, {
            body: JSON.stringify(requestBody),
            timeout: COSELL_API_TIMEOUT,
          })
          .json();
        return result['data'];
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        // @ts-ignore
        throw new Error(`An error was encountered: ${error?.message}`, {
          cause: err,
        });
      }
    },

    async updateOpportunity(
      oppID: string,
      updateRequestBody: OpportunityRequest,
    ): Promise<any | ErrorResponse> {
      const { cloud, vendorId } = updateRequestBody;
      try {
        const response = await cosellHttpClient
          .put(`api/v2/opportunity/${vendorId}/cloud/${cloud}/id/${oppID}`, {
            body: JSON.stringify(updateRequestBody),
            timeout: COSELL_API_TIMEOUT,
          })
          .json();
        return response['data'];
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        // @ts-ignore
        throw new Error('An error was encountered', { cause: err });
      }
    },

    async deleteDraftOpportunity(
      vendorId: string,
      cloud: string,
      opportunity_id: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .delete(
            `api/v2/opportunity/${vendorId}/cloud/${cloud}/delete-draft/id/${opportunity_id}`,
            {
              timeout: COSELL_API_TIMEOUT,
            },
          )
          .json();
        return result['data'];
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async requestInboundOpportunity(
      vendorId: string,
      cloud: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .post(`api/v2/simulated_opportunities/${vendorId}/cloud/${cloud}`, {
            timeout: COSELL_API_TIMEOUT,
          })
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async simulateCloudAccept(
      vendorId: string,
      cloud: string,
      opportunityId: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .post(
            `api/v2/simulated_opportunities/${vendorId}/cloud/${cloud}/id/${opportunityId}`,
            {
              timeout: COSELL_API_TIMEOUT,
              body: JSON.stringify({
                status: OpportunityStatusEnum.ACCEPTED,
              }),
            },
          )
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async simulateCloudDecline(
      vendorId: string,
      cloud: string,
      opportunityId: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .post(
            `api/v2/simulated_opportunities/${vendorId}/cloud/${cloud}/id/${opportunityId}`,
            {
              timeout: COSELL_API_TIMEOUT,
              body: JSON.stringify({
                status: OpportunityStatusEnum.DECLINED,
              }),
            },
          )
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async simulateCloudRelease(
      vendorId: string,
      cloud: string,
      opportunityId: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .post(
            `api/v2/simulated_opportunities/${vendorId}/cloud/${cloud}/id/${opportunityId}`,
            {
              timeout: COSELL_API_TIMEOUT,
              body: JSON.stringify({
                status: 'release',
              }),
            },
          )
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async getCoSellVendor(vendorId: string): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .get(`api/vendor/${vendorId}`)
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },

    async getVendorSolutionsByVendorAndCloud(
      vendorId: string,
      cloud: string,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .get(`api/v2/vendor/${vendorId}/solution/cloud/${cloud}`)
          .json();
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          // @ts-ignore
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },
  };
  return cosellClient;
};

const cosellClient = makeCosellApiClient(legacyGetAuthToken);

export default cosellClient;

import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
  cosellFormAiTextField: {
    position: 'relative',
    '& #projectDescription': {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
      borderWidth: '2px',
    },
  },
  cosellFormTopSection: {
    position: 'absolute',
    top: theme.spacing(2.75),
    left: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: theme.spacing(2),
    zIndex: 1,
    background:
      'linear-gradient(90deg, #FFF 96%, rgba(255, 255, 255, 0.00) 96%)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  tackleAiLogoText: {
    fontWeight: 600,
    lineHeight: `${theme.spacing(3)}px`,
    color: theme.palette.NEUTRAL300,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  bottomGenerateRow: {
    position: 'absolute',
    bottom: theme.spacing(3.25),
    left: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    background:
      'linear-gradient(90deg, #FFF 96%, rgba(255, 255, 255, 0.00) 96%)',
    width: '100%',
  },
  bottomGenerateRowSmall: {
    position: 'absolute',
    bottom: theme.spacing(5.5),
    left: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    background:
      'linear-gradient(90deg, #FFF 96%, rgba(255, 255, 255, 0.00) 96%)',
    width: '100%',
  },
  generateWithAiButton: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  loadingSpinner: {
    height: theme.spacing(8),
    '& > div': {},
  },
  newText: {
    backgroundColor: 'yellow',
  },
}));

export default useStyles;

import { Grid, Tooltip } from 'vendor/material';
import { useFormikContext } from 'formik';
import {
  AiTextFormField,
  CurrencyFormField,
  DateFormField,
  MultiSelectFormField,
  NextStepsList,
  RadioFormField,
  RadioCheckboxGroupFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';

import { pendoIdGenerator } from 'utils/idGenerator';
import {
  cosellMarketingActivityFormSection,
  cosellOpportunityDetailsFormSection,
  cosellBuyerCompanyInformationFormSection,
  cosellAdditionalDetailsFormSection,
} from 'utils/pendoEnums';
import { useOpportunityFormStyles } from '../UnifiedOpportunityForm.styles';
import { AceOpportunityFormValues } from './AceOpportunityFormValues';
import {
  AceNeedFromCloudEnumValues,
  AceNeedFromCloudEnumLabels,
  AceNotNeededFromCloud,
  AceOpportunityCampaignNameEnum,
  AceOpportunityDeliveryModelEnum,
  AceOpportunityMarketingActivityChannelEnum,
  AceOpportunityMarketingActivityUsecaseEnum,
  AceOpportunityTypeEnum,
  AceOpportunityUseCaseEnum,
  AceUSStatesEnum,
  CountryEnum,
  OpportunityIndustryEnum,
  OpportunityOriginationEnum,
  OpportunityStatusEnum,
  OpportunityYesNoEnum,
} from 'pages/CoSell/types/enums';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { CoSellFeatureFlags } from 'utils/features';
import { AceOpportunitySalesActivityEnum } from 'pages/CoSell/types/enums/AceOpportunitySalesActivityEnum';
import { AceOpportunitySalesStageEnum } from 'pages/CoSell/types/enums/AceOpportunitySalesStageEnum';
import { FeatureFlagState } from 'pages/CoSell/types/enums/FeatureFlags';
import { UnifiedOpportunityFormSection } from '../UnifiedOpportunityFormSection';
import { UnifiedOpportunityResponse } from 'packages/cosell/src/types/responses/UnifiedOpportunityResponse';

interface AceOpportunityFormFieldsProps {
  opportunity: UnifiedOpportunityResponse;
  solutionList: string[];
}

const useCoSellFeatureFlags = () => {
  const { loading: ampliloading, flags: ampliflags } = useAmpliFeatureFlags();
  return {
    ampliloading,
    showAiGenerateButton:
      ampliflags[CoSellFeatureFlags.BETA_AI_COSELL_DESCRIPTION] ===
      FeatureFlagState.ON
        ? true
        : false,
  };
};

const AceOpportunityFormFields = ({
  opportunity,
  solutionList,
}: AceOpportunityFormFieldsProps) => {
  const classes = useOpportunityFormStyles();
  const { values, setFieldValue } =
    useFormikContext<AceOpportunityFormValues>();
  const { touched, errors } = useFormikContext();
  const { ampliloading, showAiGenerateButton } = useCoSellFeatureFlags();

  const isANewOpportunity = !opportunity?.id;

  /**
   * An opportunity is considered closed if it is in closed lost or closed won status.
   */
  const isClosedOpportunity =
    opportunity?.status === OpportunityStatusEnum.CLOSED_LOST ||
    opportunity?.status === OpportunityStatusEnum.CLOSED_WON;

  /**
   * An opportunity is considered submitted if it is not in draft,or internal review status.
   * Action required is different from the ones above because it is a status that requires the user to take action due to an error.
   * By submitted, we mean that the opportunity has been sent to AWS and the editable fields are limited.
   */
  const hasBeenSubmitted =
    ![
      OpportunityStatusEnum.DRAFT,
      OpportunityStatusEnum.INTERNAL_REVIEW,
      OpportunityStatusEnum.ACTION_REQUIRED,
    ].includes(opportunity?.status) && !isANewOpportunity;

  /**
   * An opportunity is considered not sent to AWS when it is created within Tackle and has not been submitted to AWS or
   * is created in the Salesforce app and has internal review enabled.
   */
  const hasNotBeenSentToAWS =
    [
      OpportunityStatusEnum.DRAFT,
      OpportunityStatusEnum.INTERNAL_REVIEW,
    ].includes(opportunity?.status) || isANewOpportunity;

  const isOpportunityDraftOrNew =
    opportunity?.status === OpportunityStatusEnum.DRAFT || isANewOpportunity;

  const isOppFromMarketingActivity =
    values?.IsOppFromMarketingActivity === OpportunityYesNoEnum.YES;

  /**
   * Due to unknown values coming from AWS, we need to disable validation for inbound opportunities on certain fields.
   */
  const shouldDisableValidationForInbounds =
    opportunity?.origination === OpportunityOriginationEnum.INBOUND
      ? OpportunityStatusEnum.ACCEPTED
        ? !hasBeenSubmitted
        : hasBeenSubmitted
      : hasBeenSubmitted;

  const handleIsOppFromMarketingActivityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value === OpportunityYesNoEnum.NO) {
      setFieldValue('awsSFCampaignName', null);
      setFieldValue('marketingActivityUsecase', null);
      setFieldValue('marketingActivityChannel', null);
      setFieldValue('isMarketingDevelopmentFunded', null);
    }
  };
  console.log('Got to AceOpportunityFormFields.tsx');
  return (
    <>
      {/* Next Steps Section */}
      {isOpportunityDraftOrNew ? null : (
        <UnifiedOpportunityFormSection
          title="Next steps"
          description='The latest "Next Steps" for the opportunity are highlighted to the cloud representatives, and the latest steps from them will appear here.'
          expandable={true}
        >
          {opportunity?.details.nextStepHistory ||
          opportunity?.details.nextStep ? (
            <Grid item xs={12}>
              <NextStepsList
                nextStep={opportunity?.details.nextStep}
                nextStepHistory={opportunity?.details.nextStepHistory}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="nextStep"
              label="Add a next step"
              maxLength={255}
              disabled={isClosedOpportunity}
            />
          </Grid>
        </UnifiedOpportunityFormSection>
      )}

      {/* Section 1: Opportunity Details */}
      <UnifiedOpportunityFormSection
        title="Opportunity details"
        description="Enter the information about your referral."
      >
        <Grid item xs={12}>
          <TextFormField
            name="projectTitle"
            label="Opportunity name"
            maxLength={255}
            required
            aria-invalid={touched['projectTitle'] && errors['projectTitle']}
            disabled={!hasNotBeenSentToAWS}
            aria-disabled={!hasNotBeenSentToAWS}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-invalid={touched['crm_id'] && errors['crm_id']}
            name="crm_id"
            label="CRM co-sell ID"
            required
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiSelectFormField
            aria-disabled={isClosedOpportunity}
            fieldId={'salesActivities'}
            label={'Sales activities'}
            optionsEnum={AceOpportunitySalesActivityEnum}
            disabled={isClosedOpportunity}
            // Sales activities is required when the partner needs support from AWS.
            required={
              values.partnerNeedType !==
              AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
            }
            delimiter={';'}
            filterSelectedOptions={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CurrencyFormField
            dataId={pendoIdGenerator(
              cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_ESTIMATED_AWS_MONTHLY_RECURRING_REVENUE,
            )}
            aria-disabled={isClosedOpportunity}
            name="expectedMonthlyRevenue"
            label="Estimated AWS monthly recurring revenue"
            required
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateFormField
            aria-disabled={isClosedOpportunity}
            name="targetCloseDate"
            label="Target close date"
            required
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelectFormField
            fieldId="opportunityType"
            label="Opportunity type"
            optionsList={AceOpportunityTypeEnum}
            filterSelectedOptions={true}
            required
            disabled={isClosedOpportunity}
          />
        </Grid>
        {values.opportunityType === AceOpportunityTypeEnum.FLAT_RENEWAL ? (
          <Grid item xs={12} md={6}>
            <TextFormField
              label="Parent co-sell ID"
              name="parentOppId"
              pendoId={pendoIdGenerator(
                cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_PARENT_COSELL_ID,
              )}
              disabled={isClosedOpportunity}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <MultiSelectFormField
            aria-disabled={isClosedOpportunity}
            fieldId="solutions"
            label="Add the solution(s) for this opportunity"
            // @ts-ignore
            optionsEnum={solutionList}
            disabled={isClosedOpportunity || solutionList?.length === 0}
            required
            delimiter={';'}
            filterSelectedOptions={true}
            dataId={pendoIdGenerator(
              cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_SOLUTIONS,
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelectFormField
            fieldId="salesStage"
            label="What sales stage are you at?"
            optionsList={AceOpportunitySalesStageEnum}
            filterSelectedOptions={true}
            disabled // TODO: Read only until the API could handle it.
          />
        </Grid>
      </UnifiedOpportunityFormSection>

      {/* Section 2: Buyer company information */}
      <UnifiedOpportunityFormSection
        title="Buyer company information"
        description="Enter the details about the company you are working with."
      >
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={hasBeenSubmitted || isClosedOpportunity}
            aria-invalid={
              touched['customerCompanyName'] && errors['customerCompanyName']
            }
            name="customerCompanyName"
            label="Company name"
            maxLength={255}
            disabled={hasBeenSubmitted || isClosedOpportunity}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFormField
            name="dunsNumber"
            label="Buyer DUNS number"
            maxLength={9}
            pendoId={pendoIdGenerator(
              cosellBuyerCompanyInformationFormSection.COSELL_BUYER_COMPANY_INFORMATION_DUNS_NUMBER,
            )}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={hasBeenSubmitted || isClosedOpportunity}
            aria-invalid={
              touched['customerWebsite'] && errors['customerWebsite']
            }
            name="customerWebsite"
            label="Website"
            maxLength={255}
            disabled={hasBeenSubmitted || isClosedOpportunity}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelectFormField
            aria-disabled={
              shouldDisableValidationForInbounds || isClosedOpportunity
            }
            disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
            fieldId="industry"
            label="Industry"
            optionsList={OpportunityIndustryEnum}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelectFormField
            aria-disabled={
              shouldDisableValidationForInbounds || isClosedOpportunity
            }
            fieldId="country"
            data-testid="countrySingleSelectFormField"
            label="Country"
            optionsList={CountryEnum}
            disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
            required
          />
        </Grid>
        {values.country === CountryEnum.UNITED_STATES ? (
          <Grid item xs={12} md={3}>
            <SingleSelectFormField
              aria-disabled={
                shouldDisableValidationForInbounds || isClosedOpportunity
              }
              data-testid="stateTextFormField"
              fieldId="state"
              label="State"
              optionsList={AceUSStatesEnum}
              disabled={
                shouldDisableValidationForInbounds || isClosedOpportunity
              }
              required
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={3}>
          <TextFormField
            aria-disabled={
              shouldDisableValidationForInbounds || isClosedOpportunity
            }
            aria-invalid={touched['postalCode'] && errors['postalCode']}
            name="postalCode"
            label="Postal code"
            disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
            required
          />
        </Grid>
        {values.country === CountryEnum.UNITED_STATES &&
        values.industry === OpportunityIndustryEnum.GOVERNMENT ? (
          <Grid item xs={12} md={6}>
            <RadioFormField
              name="nationalSecurityRelated"
              label="Does this opportunity contain classified National Security information?"
              id={'data-id'}
              required
              disabled={!hasNotBeenSentToAWS}
            />
          </Grid>
        ) : null}
        {values.industry === OpportunityIndustryEnum.OTHER ? (
          <Grid item xs={12} md={6}>
            <TextFormField
              name="industryOther"
              label="Industry other"
              required
              disabled={
                shouldDisableValidationForInbounds || isClosedOpportunity
              }
            />
          </Grid>
        ) : null}
      </UnifiedOpportunityFormSection>

      {/* Section 3: Customer Contact Information */}
      <UnifiedOpportunityFormSection
        title="Buyer contact information"
        description="Providing this information allows the cloud to support you in co-selling."
        tooltip="This helps AWS progress faster on the opportunity. If not filled, AWS will populate with opportunity submitter information."
      >
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="customerFirstName"
            label="First name"
            maxLength={80}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="customerLastName"
            label="Last name"
            maxLength={80}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="customerEmail"
            label="Email"
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="customerPhone"
            label="Phone"
            disabled={isClosedOpportunity}
          />
        </Grid>
      </UnifiedOpportunityFormSection>

      {/* Section 4: Your contact information */}
      <UnifiedOpportunityFormSection
        title="Your contact information"
        description="Enter details about the contact from your origination to keep them updated. This could be you or someone else from your company."
        tooltip="AWS sales team may reach out to this contact in regard to the opportunity."
      >
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="primaryContactFirstName"
            label="First name"
            maxLength={255}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="primaryContactLastName"
            label="Last name"
            maxLength={255}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="primaryContactEmail"
            label="Email"
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="primaryContactPhone"
            label="Phone"
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            aria-disabled={isClosedOpportunity}
            name="opportunityOwnerEmail"
            label="APN registered email (optional)"
            disabled={isClosedOpportunity}
          />
        </Grid>
      </UnifiedOpportunityFormSection>

      {/* Section 5: Marketing Activity */}
      <UnifiedOpportunityFormSection
        title="Marketing activity"
        description="Information requested by AWS."
      >
        <Grid item container xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <RadioFormField
              aria-disabled={isClosedOpportunity}
              label={'Is opportunity from marketing activity'}
              id={pendoIdGenerator(
                cosellMarketingActivityFormSection.IS_OPP_FROM_MARKETING_ACTIVITY,
              )}
              name="IsOppFromMarketingActivity"
              required
              onChange={handleIsOppFromMarketingActivityChange}
              disabled={isClosedOpportunity}
            />
          </Grid>
          {isOppFromMarketingActivity && (
            <>
              <Grid item xs={12}>
                <TextFormField
                  aria-disabled={isClosedOpportunity}
                  pendoId={pendoIdGenerator(
                    cosellMarketingActivityFormSection.AWS_MARKETING_SALESFORCE_CAMPAIGN_NAME,
                  )}
                  name="awsSFCampaignName"
                  label="AWS marketing Salesforce campaign name"
                  maxLength={255}
                  disabled={isClosedOpportunity}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  aria-disabled={isClosedOpportunity}
                  fieldId={'marketingActivityUsecase'}
                  label={'Marketing activity use-case'}
                  optionsEnum={AceOpportunityMarketingActivityUsecaseEnum}
                  disabled={isClosedOpportunity}
                  required={false}
                  delimiter={';'}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  aria-disabled={isClosedOpportunity}
                  fieldId={'marketingActivityChannel'}
                  label={'Marketing activity channel'}
                  optionsEnum={AceOpportunityMarketingActivityChannelEnum}
                  disabled={isClosedOpportunity}
                  required={false}
                  delimiter={';'}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioFormField
                  aria-disabled={isClosedOpportunity}
                  label={'Were marketing development funds used?'}
                  name="isMarketingDevelopmentFunded"
                  id={pendoIdGenerator(
                    cosellMarketingActivityFormSection.WERE_MARKETING_DEVELOPMENT_FUNDS_USED,
                  )}
                  required
                  disabled={isClosedOpportunity}
                />
              </Grid>
            </>
          )}
        </Grid>
      </UnifiedOpportunityFormSection>

      {/* Section 6: Additional Details */}
      <UnifiedOpportunityFormSection
        title="Additional details"
        description="Provide supporting information about the opportunity."
      >
        <Grid item container xs={12}>
          <Grid item xs={12} md={6}>
            <RadioCheckboxGroupFormField
              aria-disabled={isClosedOpportunity}
              disabled={isClosedOpportunity}
              id="partnerNeedType"
              label="Partner primary need from AWS"
              optionValuesEnum={AceNeedFromCloudEnumValues}
              optionLabelsEnum={AceNeedFromCloudEnumLabels}
              optionsHelperText={'Partner specific needs from AWS for co-sell'}
              delimiter={';'}
              radioLabelYes={'Co-sell with AWS'}
              radioLabelNo={'Do not need support from AWS sales rep'}
              radioLabelYesHelperText={
                'Share the opportunity with AWS to receive deal assistance and support.'
              }
              radioLabelNoHelperText={
                'Share the opportunity with AWS for visibility only, you will not receive deal assistance or support.'
              }
              radioValueNo={
                AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              required
              name={'partnerNeedType'}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiSelectFormField
            delimiter={';'}
            fieldId="deliveryModel"
            filterSelectedOptions={true}
            label="Delivery model"
            optionsEnum={AceOpportunityDeliveryModelEnum}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelectFormField
            aria-disabled={isClosedOpportunity}
            disabled={isClosedOpportunity}
            fieldId="useCase"
            filterSelectedOptions={true}
            label="Use case"
            optionsList={AceOpportunityUseCaseEnum}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiSelectFormField
            fieldId="campaignName"
            label={'APN programs'}
            optionsEnum={AceOpportunityCampaignNameEnum}
            delimiter={';'}
            filterSelectedOptions={true}
            required={false}
            disabled={isClosedOpportunity}
          />
        </Grid>
        <Grid item xs={12}>
          <CalloutWrapper
            isTextFieldDisabled={
              shouldDisableValidationForInbounds || isClosedOpportunity
            }
            wrapper={(children) => (
              <Tooltip
                title={<ProjectDescriptionCallout />}
                placement="top-start"
                disableHoverListener
                className={classes.calloutContainer}
                classes={{ tooltip: classes.callout }}
              >
                <div>{children}</div>
              </Tooltip>
            )}
          >
            {!ampliloading && showAiGenerateButton ? (
              <AiTextFormField
                aria-disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
                aria-invalid={
                  touched['projectDescription'] && errors['projectDescription']
                }
                name="projectDescription"
                dependsOnFields={['customerWebsite']}
                label="Buyer business problem"
                multiline
                maxLength={32000}
                minLength={50}
                placeholder="Add your own description, or select Generate with Tackle AI to get started"
                required
                disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
              />
            ) : (
              <TextFormField
                pendoId={
                  cosellAdditionalDetailsFormSection.COSELL_ADDITIONAL_DETAILS_BUYER_BUSINESS_PROBLEM
                }
                aria-disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
                aria-invalid={
                  touched['projectDescription'] && errors['projectDescription']
                }
                name="projectDescription"
                label="Buyer business problem"
                multiline
                rows={4}
                maxLength={32000}
                minLength={50}
                required
                disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
              />
            )}
          </CalloutWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            name="additionalComments"
            label="Notes"
            multiline
            rows={6}
            maxLength={32000}
          />
        </Grid>
      </UnifiedOpportunityFormSection>
    </>
  );
};

const ProjectDescriptionCallout = () => {
  return (
    <>
      <p>
        Describe any pre-sales efforts related to the opportunity (e.g., meeting
        with the end-user decision-makers, defining technology stack on AWS,
        etc.) and a clear description of the customer needs and your proposed
        solution.
      </p>
      <p>Provide the following details:</p>
      <ul>
        <li>
          What is the end customer looking to do on AWS? What is your role, the
          APN Partner, in this project?
        </li>
        <li>
          What are the software applications and tools used to support the
          solution?
        </li>
        <li>
          Specific department or subsidiary of the end customer you, the APN
          partner, are working with?
        </li>
      </ul>
      <p>Minimum 50 characters description required.</p>
    </>
  );
};

// We need to conditionally render a tooltip around the Project Description field
// because the disabled styles of the field are not applied when the field is
// is wrapped in the tooltip.
const CalloutWrapper = ({
  isTextFieldDisabled,
  wrapper,
  children,
}: {
  isTextFieldDisabled: boolean;
  wrapper: React.FC<JSX.Element>;
  children: JSX.Element;
}) => (isTextFieldDisabled ? children : wrapper(children));

export default AceOpportunityFormFields;

import { Typography } from 'vendor/material';
import { useNextStepsListStyles } from './NextStepsList.styles';

interface NextStepsListProps {
  nextStep: string;
  nextStepHistory: string;
}

export const NextStepsList = ({
  nextStep,
  nextStepHistory,
}: NextStepsListProps) => {
  const classes = useNextStepsListStyles();

  if (!nextStepHistory && !nextStep)
    return (
      <Typography variant="body2" className={classes.noNextStepsText}>
        When added, "Next steps" will appear here.
      </Typography>
    );

  const nextSteps = nextStepHistory?.split(';');

  return (
    <>
      <Typography component="h5" className={classes.nextStepsLabel}>
        Next steps
      </Typography>
      <ul className={classes.nextStepsList}>
        {!nextStepHistory ? (
          <li>{nextStep}</li>
        ) : (
          nextSteps.map((step, index) =>
            step === 'null' ? null : <li key={index}>{step}</li>,
          )
        )}
      </ul>
    </>
  );
};

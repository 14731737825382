export const convertYesNoToBoolean = (
  value: string,
  defaultValue: boolean | null = null,
) => {
  switch (value) {
    case 'Yes':
      return true;
    case 'No':
      return false;
    default:
      return defaultValue;
  }
};

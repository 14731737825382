import { useCurrentUserVendorQuery } from 'generated/graphql';
import { createContext, useContext } from 'react';

interface OpportunityContextProps {
  vendorId: string;
}

export const OpportunityContext = createContext<OpportunityContextProps>({
  vendorId: null,
});

export interface OpportunityProviderProps {
  children?: React.ReactElement;
}

export const OpportunityProvider = ({ children }: OpportunityProviderProps) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const vendorId = currentUserData?.currentUser?.vendor?.id;

  return (
    <OpportunityContext.Provider
      value={{
        vendorId,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error(
      'useOpportunity must be used within a PaymentsDataProvider',
    );
  }
  return context;
}

import { TextField } from '@tackle-io/platform-ui';
import { Delete } from 'mdi-material-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, IconButton, Typography } from 'vendor/material';
import AwsScheduleDateField from './AwsScheduleDateField';
import useStyles from './styles';
import { ScheduleFieldProps } from './typings';
import { scheduleFormPath } from '../utils';

const AwsScheduleField: React.FC<ScheduleFieldProps> = ({
  field,
  index,
  onRemove,
  numberOfPaymentScheduleRows,
}) => {
  const { control, errors } = useFormContext();
  const classes = useStyles();

  const showDeleteIcon = () => {
    if (index === 0) {
      return numberOfPaymentScheduleRows > 1;
    }

    return true;
  };

  return (
    <Grid container spacing={2} className={classes.awsDimensionFields}>
      <Grid item xs={1} className={classes.paymentNumberContainer}>
        <Typography component="p" className={classes.paymentNumber}>{`${
          index + 1
        }`}</Typography>
      </Grid>

      <Grid item xs={7} className={classes.priceContainer}>
        <AwsScheduleDateField field={field} index={index} />
      </Grid>

      <Grid
        item
        xs={3}
        className={`${classes.fieldKey} ${classes.invoiceAmountContainer}`}
      >
        <Controller
          name={`${scheduleFormPath}[${index}].invoiceAmount`}
          control={control}
          defaultValue={field.invoiceAmount ?? ''}
          render={({ ref, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label={index === 0 ? 'Payment *' : 'Payment'}
              id={`${scheduleFormPath}.${index}.invoiceAmount`}
              error={errors.pricing?.schedule?.[index]?.invoiceAmount?.message}
              mode="numberformat"
              fixedDecimalScale
              type="number"
              aria-label={`Payment ${index} invoice amount`}
              inputProps={{ ref }}
            />
          )}
        />
      </Grid>

      {showDeleteIcon() && (
        <Grid item xs={1} className={classes.deleteIcon}>
          <IconButton
            onClick={(): void => onRemove(index)}
            aria-label={`Delete payment ${index} button`}
          >
            <Delete className={classes.buttonIcon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default AwsScheduleField;

import {
  Calendar,
  Dropdown,
  Select,
  TextField,
  useTextFieldStyles,
} from '@tackle-io/platform-ui';
import { DateTime } from 'luxon';
import { Calendar as CalendarIcon } from 'mdi-material-ui';
import {
  maxFirstPaymentDate,
  minFirstPaymentDate,
} from 'pages/PrivateOffers/utils/formatFormData';
import { FC, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { formatDate } from 'utils/dates';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Popover,
} from 'vendor/material';
import useStyles from './PaymentScheduler.styles';
import { paymentFrequencyOptions } from './constants';

interface FieldsPaymentSchedulerProps {
  control: Control;
  errors: any;
  disableDurationField: boolean;
}

export const FieldsPaymentScheduler: FC<FieldsPaymentSchedulerProps> = ({
  control,
  errors,
  disableDurationField = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputClasses = useTextFieldStyles();
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent): void =>
    setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);
  const open = !!anchorEl;
  const id = open ? 'date-popover' : undefined;
  const minSelectableDate = minFirstPaymentDate();
  const maxSelectableDate = maxFirstPaymentDate();
  const paymentFrequencyDefault = paymentFrequencyOptions[0]?.value;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box>
          <Controller
            control={control}
            name="totalContractValue"
            render={({ ref, onChange, ...props }): React.ReactElement => (
              <TextField
                {...props}
                label="Total contract value"
                type="number"
                mode="numberformat"
                helperText="Total contract value of all the payments in the schedule"
                inputRef={ref}
                onChange={({ target: { value } }) => onChange(value)}
                error={errors?.totalContractValue?.message?.replace(
                  'totalContractValue',
                  'Total contract value',
                )}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="durationInMonths"
            render={({ ref, ...props }): React.ReactElement => (
              <TextField
                {...props}
                label="Duration in months"
                type="number"
                helperText="Length of contract in months. Changes made here are applied to the offer form."
                inputRef={ref}
                error={errors?.durationInMonths?.message?.replace(
                  'durationInMonths',
                  'Duration in months',
                )}
                readOnly={disableDurationField}
                disableClearAll={disableDurationField}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="paymentFrequency"
            defaultValue={paymentFrequencyDefault}
            render={({ ref, onChange, ...props }): React.ReactElement => (
              <Select
                {...props}
                label="Payment frequency"
                placeholder="Payment frequency"
                onChange={onChange}
                helperText="How often payments will be created"
              >
                {paymentFrequencyOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </Select>
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="firstPaymentDate"
            control={control}
            defaultValue={''}
            shouldUnregister
            render={({ ref, onChange, value }): React.ReactElement => (
              <FormControl
                error={!!errors?.firstPaymentDate}
                ref={ref}
                fullWidth
              >
                <InputLabel className={inputClasses.inputLabel}>
                  Date of first payment
                </InputLabel>
                <Dropdown
                  value={
                    value
                      ? formatDate(
                          DateTime.fromJSDate(value),
                          DateTime.DATE_SHORT,
                        )
                      : ''
                  }
                  onClick={handleClick}
                  startIcon={<CalendarIcon />}
                  fullWidth
                  hideEndIcon
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  classes={{
                    paper: classes.paper,
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={classes.calendarContent}>
                    <Calendar
                      onChange={(date): void => {
                        onChange(date);
                        setAnchorEl(null);
                      }}
                      value={value}
                      minDate={minSelectableDate}
                      maxDate={maxSelectableDate}
                    />
                  </div>
                </Popover>
                <FormHelperText error={!!errors?.firstPaymentDate}>
                  {errors?.firstPaymentDate
                    ? errors?.firstPaymentDate?.message?.replace(
                        'firstPaymentDate',
                        'Date of first payment',
                      )
                    : ''}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default FieldsPaymentScheduler;

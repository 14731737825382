import { AceOpportunityFormValues } from 'pages/CoSell/components/OpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { OpportunityResponse } from 'pages/CoSell/types/responses/OpportunityResponse';
import { convertBooleanToYesNo } from '../convertBooleansToYesNo';
import {
  AceOpportunityPartnerAcceptanceStatusEnum,
  OpportunityMemberTitleEnum,
  OpportunityMemberTypeEnum,
  OpportunityYesNoEnum,
} from 'pages/CoSell/types/enums';
import { solutionsApiToFormInitialValueFormat } from '../formatSolutions';

export const getInitialAceFormValues = (
  opportunity?: OpportunityResponse,
): AceOpportunityFormValues => {
  const initialIndustry = opportunity?.details?.industries
    ? opportunity?.details?.industries[0]?.externalId
    : '';
  const initialUseCase = opportunity?.details?.useCases
    ? opportunity?.details?.useCases[0]?.externalId
    : '';
  const initialSalesActivities = opportunity?.details?.salesActivities
    ? opportunity?.details?.salesActivities?.join(';')
    : '';

  const initialSolutions = opportunity?.details?.solutions?.length
    ? solutionsApiToFormInitialValueFormat(opportunity?.details?.solutions)
    : '';
  const initialOffers = opportunity?.details?.offers
    ? opportunity?.details?.offers?.join(';')
    : '';

  return {
    id: opportunity?.id,
    status: opportunity?.status,
    opportunityType: opportunity?.type ?? '',
    parentOppId: opportunity?.externalSecondaryId ?? '',
    partnerAcceptanceStatus:
      opportunity?.accepted === undefined
        ? ''
        : opportunity?.accepted === null
        ? ''
        : opportunity?.accepted === true
        ? AceOpportunityPartnerAcceptanceStatusEnum.ACCEPTED
        : AceOpportunityPartnerAcceptanceStatusEnum.REJECTED,
    nextStep: undefined,
    nextStepHistory: opportunity?.details?.nextStepHistory ?? '',
    nationalSecurityRelated: convertBooleanToYesNo(
      opportunity?.details?.nationalSecurityRelated,
    ),
    projectTitle: opportunity?.title ?? '',
    crm_id: opportunity?.externalCrmCosellId ?? '',
    cloudServiceOpportunityId: opportunity?.externalOpportunityId ?? '',
    projectDescription: opportunity?.description ?? '',
    expectedMonthlyRevenue: opportunity?.pricing?.dealValueMonthly,
    targetCloseDate: opportunity?.targetCloseDate,
    rejectionReason: opportunity?.details?.rejectionReason ?? '',
    primaryContactFirstName:
      findPrimaryContactMemberInfo(opportunity?.members)?.firstName ?? '',
    primaryContactLastName:
      findPrimaryContactMemberInfo(opportunity?.members)?.lastName ?? '',
    primaryContactEmail:
      findPrimaryContactMemberInfo(opportunity?.members)?.email ?? '',
    primaryContactPhone:
      findPrimaryContactMemberInfo(opportunity?.members)?.phone ?? '',
    opportunityOwnerEmail:
      findOpportunityOwnerInfo(opportunity?.members)?.email ?? '',
    opportunityOwnerName:
      findOpportunityOwnerInfo(opportunity?.members)?.fullName ?? '',
    customerCompanyName: opportunity?.customer?.companyName ?? '',
    customerWebsite: opportunity?.customer?.website ?? '',
    country: opportunity?.customer?.country ?? '',
    state: opportunity?.customer?.state ?? '',
    postalCode: opportunity?.customer?.postalCode ?? '',
    industry: initialIndustry,
    industryOther: opportunity?.details?.industryOther ?? '',
    campaignName: opportunity?.details?.campaignName ?? '',
    contractVehicle: opportunity?.details?.contractVehicle ?? '',
    offers: initialOffers,
    rfxSolicitationNumber: opportunity?.details?.rfxSolicitationNumber ?? '',
    customerFirstName:
      findCustomerContactInfo(opportunity?.members)?.firstName ?? '',
    customerLastName:
      findCustomerContactInfo(opportunity?.members)?.lastName ?? '',
    customerEmail: findCustomerContactInfo(opportunity?.members)?.email ?? '',
    customerPhone: findCustomerContactInfo(opportunity?.members)?.phone ?? '',
    dunsNumber: opportunity?.customer?.dunsNumber ?? '',
    // field has a default so no nullable check needed
    IsOppFromMarketingActivity:
      opportunity?.details?.isFromMarketingActivity === true
        ? OpportunityYesNoEnum.YES
        : OpportunityYesNoEnum.NO,
    awsSFCampaignName: opportunity?.details?.marketingCampaignName ?? '',
    marketingActivityUsecase:
      opportunity?.details?.marketingActivityUseCase ?? '',
    marketingActivityChannel:
      opportunity?.details?.marketingActivityChannel ?? '',
    isMarketingDevelopmentFunded: convertBooleanToYesNo(
      opportunity?.details?.isMarketingDevelopmentFunded,
    ),
    deliveryModel: opportunity?.details?.deliveryModel ?? '',
    useCase: initialUseCase,
    salesActivities: initialSalesActivities,
    solutions: initialSolutions,
    salesStage: opportunity?.details?.status ?? '',
    partnerNeedType: opportunity?.details?.needFromCloud ?? '',
    partnerNeedTypeOther: opportunity?.details?.needFromCloudOther ?? '',
    additionalComments: opportunity?.details?.notes ?? '',
    // closed won/lost modal fields
    receivedSupportFromCloudRep: convertBooleanToYesNo(
      opportunity?.details?.receivedSupportFromCloudRep,
    ),
    isThisForMarketplace: convertBooleanToYesNo(
      opportunity?.details?.isForMarketplace,
    ),
    isNetNewBusinessForCompany: convertBooleanToYesNo(
      opportunity?.details?.isNetNewBusiness,
    ),
    statusReason: opportunity?.details?.statusReason ?? '',
    // pinned menu contact fields
    awsAccountOwnerName:
      findCloudRepAccountMgrInfo(opportunity?.members)?.fullName ?? '',
    awsAccountOwnerEmail:
      findCloudRepAccountMgrInfo(opportunity?.members)?.email ?? '',
    awsSalesRepName:
      findCloudRepSalesRepInfo(opportunity?.members)?.fullName ?? '',
    awsSalesRepEmail:
      findCloudRepSalesRepInfo(opportunity?.members)?.email ?? '',
    awsISVSuccessManagerName:
      findCloudRepISVSuccessMgrInfo(opportunity?.members)?.fullName ?? '',
    awsISVSuccessManagerEmail:
      findCloudRepISVSuccessMgrInfo(opportunity?.members)?.email ?? '',
    awsPartnerSuccessManagerName:
      findCloudRepPartnerSuccessMgrInfo(opportunity?.members)?.fullName ?? '',
    awsPartnerSuccessManagerEmail:
      findCloudRepPartnerSuccessMgrInfo(opportunity?.members)?.email ?? '',
    partnerDeveloperManagerName:
      findCloudRepDevMgrInfo(opportunity?.members)?.fullName ?? '',
    partnerDeveloperManagerEmail:
      findCloudRepDevMgrInfo(opportunity?.members)?.email ?? '',
    wwpsDevelopmentManagerName:
      findCloudRepWWPSDevMgrInfo(opportunity?.members)?.fullName ?? '',
    wwpsDevelopmentManagerEmail:
      findCloudRepWWPSDevMgrInfo(opportunity?.members)?.email ?? '',
  };
};

const findOpportunityOwnerInfo = (members) => {
  const opportunityOwnerMember = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.VENDOR &&
      member.title === OpportunityMemberTitleEnum.OPPORTUNITY_OWNER,
  );
  return opportunityOwnerMember;
};

const findPrimaryContactMemberInfo = (members) => {
  const primaryContactMember = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.VENDOR &&
      member.title === OpportunityMemberTitleEnum.PRIMARY_CONTACT,
  );
  return primaryContactMember;
};

const findCustomerContactInfo = (members) => {
  const customerMember = members?.find(
    (member) => member.type === OpportunityMemberTypeEnum.CUSTOMER,
  );
  return customerMember;
};

const findCloudRepAccountMgrInfo = (members) => {
  const cloudRepMemberAccountMgr = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.ACCOUNT_MANAGER,
  );
  return cloudRepMemberAccountMgr;
};

const findCloudRepDevMgrInfo = (members) => {
  const cloudRepMemberDevMgr = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.DEVELOPMENT_MANAGER,
  );
  return cloudRepMemberDevMgr;
};

const findCloudRepSalesRepInfo = (members) => {
  const cloudRepMemberSalesRep = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.SALES_REP,
  );
  return cloudRepMemberSalesRep;
};

const findCloudRepISVSuccessMgrInfo = (members) => {
  const cloudRepMemberISVSuccessMgr = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.ISV_SUCCESS_MANAGER,
  );
  return cloudRepMemberISVSuccessMgr;
};

const findCloudRepPartnerSuccessMgrInfo = (members) => {
  const cloudRepMemberPartnerSuccessMgr = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.SUCCESS_MANAGER,
  );
  return cloudRepMemberPartnerSuccessMgr;
};

const findCloudRepWWPSDevMgrInfo = (members) => {
  const cloudRepMemberWWPSDevMgr = members?.find(
    (member) =>
      member.type === OpportunityMemberTypeEnum.CLOUD &&
      member.title === OpportunityMemberTitleEnum.WWPS_DEVELOPMENT_MANAGER,
  );
  return cloudRepMemberWWPSDevMgr;
};

import { AceOpportunityFormValues } from 'pages/CoSell/components/OpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import emptyStringToNull from 'utils/emptyStringToNull';
import { convertYesNoToBoolean } from '../convertYesNoToBoolean';
import {
  CoSellCloudType,
  OpportunityIndustryEnum,
  OpportunityMemberTitleEnum,
  OpportunityMemberTypeEnum,
  AceOpportunityPartnerAcceptanceStatusEnum,
} from 'pages/CoSell/types/enums';
import { solutionsFormInitialValueToApiFormat } from '../formatSolutions';
import {
  OpportunityRequest,
  OpportunityRequestCustomer,
  OpportunityRequestDetail,
  OpportunityRequestMember,
  OpportunityRequestPricing,
} from 'pages/CoSell/types/requests/OpportunityRequest';

// TODO: since we only have one request type now,
// we need to combine convertAceFormValuesToCreateRequest and convertAceFormValuesToUpdateRequest - LM 05/08/2024

export const getUpdateDetails = (
  values: AceOpportunityFormValues,
): OpportunityRequestDetail => {
  const industries = values.industry
    ? [
        {
          externalId: values.industry,
          type: OpportunityIndustryEnum[values.industry],
        },
      ]
    : null;

  const useCases = values.useCase
    ? [
        // not using enum due to v13 and v14 differences update after v13 is deprecated 4/17/24
        {
          externalId: values.useCase,
          type: values.useCase,
        },
      ]
    : null;

  const salesActivities = values?.salesActivities
    ? values?.salesActivities?.split(';')
    : null;

  const solutions = values?.solutions
    ? solutionsFormInitialValueToApiFormat(values?.solutions)
    : null;
  const offers = values?.offers ? values?.offers?.split(';') : null;

  const details = {
    campaignName: emptyStringToNull(values?.campaignName),
    contractVehicle: emptyStringToNull(values?.contractVehicle),
    deliveryModel: emptyStringToNull(values.deliveryModel),
    industries: industries,
    industryOther: emptyStringToNull(values?.industryOther),
    isForMarketplace: convertYesNoToBoolean(values?.isThisForMarketplace),
    isFromMarketingActivity:
      values.IsOppFromMarketingActivity === 'Yes' ? true : false ?? null,
    isMarketingDevelopmentFunded: convertYesNoToBoolean(
      values.isMarketingDevelopmentFunded,
    ),
    isNetNewBusiness: convertYesNoToBoolean(values?.isNetNewBusinessForCompany),
    marketingActivityChannel: emptyStringToNull(
      values?.marketingActivityChannel,
    ),
    marketingActivityUseCase: emptyStringToNull(
      values?.marketingActivityUsecase,
    ),
    marketingCampaignName: emptyStringToNull(values?.awsSFCampaignName),
    nationalSecurityRelated: convertYesNoToBoolean(
      values?.nationalSecurityRelated,
    ),
    needFromCloud: emptyStringToNull(values?.partnerNeedType),
    needFromCloudOther: emptyStringToNull(values?.partnerNeedTypeOther),
    nextStep: values?.nextStep,
    nextStepHistory: emptyStringToNull(values?.nextStepHistory),
    notes: emptyStringToNull(values?.additionalComments),
    offers: offers,
    receivedSupportFromCloudRep: convertYesNoToBoolean(
      values?.receivedSupportFromCloudRep,
    ),
    rfxSolicitationNumber: emptyStringToNull(values?.rfxSolicitationNumber),
    statusReason: emptyStringToNull(values?.statusReason),
    useCases: useCases,
    salesActivities: salesActivities,
    solutions: solutions,
    status: emptyStringToNull(values?.salesStage),
  };

  return details;
};

export const getPricing = (
  values: AceOpportunityFormValues,
): OpportunityRequestPricing => {
  const pricing: OpportunityRequestPricing = {
    dealValueMonthly: values.expectedMonthlyRevenue,
  };

  return pricing;
};

export const getCustomer = (
  values: AceOpportunityFormValues,
): OpportunityRequestCustomer => {
  const customerCreateRequest: OpportunityRequestCustomer = {
    companyName: values?.customerCompanyName,
    website: emptyStringToNull(values?.customerWebsite),
    country: values?.country,
    dunsNumber: emptyStringToNull(values?.dunsNumber),
    state: emptyStringToNull(values?.state),
    postalCode: emptyStringToNull(values?.postalCode),
  };

  return customerCreateRequest;
};

export const getMembers = (
  values: AceOpportunityFormValues,
): OpportunityRequestMember[] => {
  const membersList: OpportunityRequestMember[] = [
    {
      firstName: values?.primaryContactFirstName,
      lastName: values?.primaryContactLastName,
      email: values?.primaryContactEmail,
      phone: values?.primaryContactPhone,
      title: OpportunityMemberTitleEnum.PRIMARY_CONTACT,
      type: OpportunityMemberTypeEnum.VENDOR,
    },
    {
      firstName: values?.customerFirstName,
      lastName: values?.customerLastName,
      email: values?.customerEmail,
      phone: values?.customerPhone,
      title: OpportunityMemberTitleEnum.CUSTOMER_TEAM,
      type: OpportunityMemberTypeEnum.CUSTOMER,
    },
    {
      fullName: values?.opportunityOwnerName,
      email: values?.opportunityOwnerEmail,
      title: OpportunityMemberTitleEnum.OPPORTUNITY_OWNER,
      type: OpportunityMemberTypeEnum.VENDOR,
    },
    {
      fullName: values?.awsSalesRepName,
      email: values?.awsSalesRepEmail,
      title: OpportunityMemberTitleEnum.SALES_REP,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
    {
      fullName: values?.awsAccountOwnerName,
      email: values?.awsAccountOwnerEmail,
      title: OpportunityMemberTitleEnum.ACCOUNT_MANAGER,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
    {
      fullName: values?.awsISVSuccessManagerName,
      email: values?.awsISVSuccessManagerEmail,
      title: OpportunityMemberTitleEnum.ISV_SUCCESS_MANAGER,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
    {
      fullName: values?.awsPartnerSuccessManagerName,
      email: values?.awsPartnerSuccessManagerEmail,
      title: OpportunityMemberTitleEnum.SUCCESS_MANAGER,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
    {
      fullName: values?.partnerDeveloperManagerName,
      email: values?.partnerDeveloperManagerEmail,
      title: OpportunityMemberTitleEnum.DEVELOPMENT_MANAGER,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
    {
      fullName: values?.wwpsDevelopmentManagerName,
      email: values?.wwpsDevelopmentManagerEmail,
      title: OpportunityMemberTitleEnum.WWPS_DEVELOPMENT_MANAGER,
      type: OpportunityMemberTypeEnum.CLOUD,
    },
  ];
  return membersList;
};

export const convertAceFormValuesToUpdateRequest = (
  values: AceOpportunityFormValues,
  vendorId: string,
): OpportunityRequest => {
  return {
    accepted:
      values.partnerAcceptanceStatus === undefined
        ? null
        : values.partnerAcceptanceStatus ===
          AceOpportunityPartnerAcceptanceStatusEnum.ACCEPTED,
    cloud: CoSellCloudType.ACE,
    targetCloseDate: values?.targetCloseDate,
    description: emptyStringToNull(values?.projectDescription),
    externalCrmCosellId: emptyStringToNull(values?.crm_id),
    externalSecondaryId: emptyStringToNull(values?.parentOppId),
    id: values.id,
    status: values?.status,
    title: emptyStringToNull(values?.projectTitle),
    type: emptyStringToNull(values?.opportunityType),
    vendorId: vendorId,
    customer: getCustomer(values),
    details: getUpdateDetails(values),
    pricing: getPricing(values),
    members: getMembers(values),
  };
};

import { Solution } from '../types/requests/OpportunityRequest';

export const solutionsApiToFormFormatter = (
  solutions: Solution[],
): string[] => {
  return solutions?.map((s) => `${s.solutionName}: ${s.externalId}`);
};

// Join the resulted string array with a semicolon to be used as form initial value
export const solutionsApiToFormInitialValueFormat = (
  solutions: Solution[],
): string => {
  return solutionsApiToFormFormatter(solutions)?.join(';');
};

// Split the form initial value string by semicolon and map it to the Solution type
export const solutionsFormInitialValueToApiFormat = (
  solutions: string,
): Solution[] => {
  return solutions?.split(';')?.map((solutionNameAndId) => {
    const [name, extId] = solutionNameAndId?.split(':');
    return {
      solutionName: name.trim(),
      externalId: extId.trim(),
    };
  });
};
